import React from 'react';
import { IconContext } from "react-icons";
import { Card, CardText, Row, Col } from 'reactstrap';

import classes from './CountWidget.module.css';
const countWidget = (props) => {
    return (
        <Col sm={props.colSize}>
            <Card body className={classes.Widget}>
                <CardText>
                    <Row>
                        <Col xs="4">
                            <Row className={classes.WidgetIconContainer}>
                                <IconContext.Provider value={{ className: classes.WidgetIcon}}>
                                    {props.icon}
                                </IconContext.Provider>
                            </Row>
                        </Col>
                        <Col xs="8">
                            <Row>
                                <Col className={classes.WidgetCount}>
                                    {props.count}
                                </Col>
                            </Row>
                            <Row>
                                <Col className={classes.WidgetLabel}>
                                    {props.label}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardText>
            </Card>
        </Col>
    );
}

export default countWidget;