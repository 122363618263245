import * as actionTypes from './actionTypes';
import axios from '../../axios-orders';

export const fetchFormStart = () => {
    return {
        type: actionTypes.FETCH_FORM_START
    }
};

export const fetchFormSuccess = (form, success, tableContainedWith) => {
    return {
        type: actionTypes.FETCH_FORM_SUCCESS,
        form: form,
        fetchSuccess: success, 
        tableContainedWith: tableContainedWith
    };
};

export const fetchFormFail = (error) => {
    return {
        type: actionTypes.FETCH_FORM_FAIL,
        fetchError: error
    };
};

export const clearFormFetchError = () => {
    return {
        type: actionTypes.CLEAR_FORM_FETCH_ERROR,
        fetchError: null,
        fetchSuccess: null
    };
}

// Middleware
export const fetchForm = (token, iamsafeQRCode) => {
    return dispatch => {
        dispatch(fetchFormStart());
        const queryParams = '?auth=' + token + '&iamsafeQRCode=' + iamsafeQRCode;
        axios.get('/Baliwag_HIMS_VAC/vaccinationForm' + queryParams)
            .then(res => {
                try {
                    // console.log(res.data.error);
                    if (!res.data.error) {
                        dispatch(fetchFormSuccess(res.data.form, res.data.success));
                    } else {
                        dispatch(fetchFormFail(res.data.error));
                    }
                } catch (error) {
                    console.log("ERROR", error);
                }
            })
            .catch(err => {
                console.log("ERR", err);
                dispatch(fetchFormFail(err.message));
            });
    };
};