import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import authReducer from './store/reducers/auth';
import scannerReducer from './store/reducers/scanner';
import householdsReducer from './store/reducers/households';
import familiesReducer from './store/reducers/families';
import membersReducer from './store/reducers/members';
import dashboardReducer from './store/reducers/dashboard';
import vacScannerReducer from './store/reducers/vacScanner';
import vacTriageReducer from './store/reducers/vacTriage';
import vacScreeningReducer from './store/reducers/vacScreening';
import vacVaccinationReducer from './store/reducers/vacVaccination';
import vacHoldingReducer from './store/reducers/vacHolding';
import vacFormReducer from './store/reducers/vacForm';
import vacLinkingReducer from './store/reducers/vacLinking';
import vacVaccineeProfileReducer from './store/reducers/vacVaccinee';

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const rootReducer = combineReducers({
  auth: authReducer,
  scanner: scannerReducer,
  households: householdsReducer,
  families: familiesReducer,
  members: membersReducer,
  dashboard: dashboardReducer,
  vacScanner: vacScannerReducer,
  vacTriage: vacTriageReducer,
  vacScreening: vacScreeningReducer,
  vacVaccination: vacVaccinationReducer,
  vacHolding: vacHoldingReducer,
  vacForm: vacFormReducer,
  vacLinking: vacLinkingReducer,
  vacVaccinee: vacVaccineeProfileReducer
});

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(
  <React.StrictMode>
    {app}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
