import React from 'react';

import { Row, Col, Input } from 'reactstrap';

export const barangay = (props) => {
    // let barangays = null;
    const barangays = Object.entries(props.barangays).map(([key, value]) => { 
        // totalCount += parseInt(value);
        // return console.log(key, value);
        // console.log(value.brgyDesc);
        // barangays += <option>{value.brgyDesc}</option>;
        const isSelected = (value.brgyDesc === props.selectedBarangay) ? true : false;
        return <option key={key} selected={isSelected}>{value.brgyDesc}</option>;
    });

    return (
        <Row style={{marginBottom: '10px'}}>
            <Col xs="12">
                <small>Filter Dashboard by Barangay</small>
                <Input type="select" onChange={props.onChange}>
                    <option>ALL</option>
                    {barangays}
                </Input>
            </Col>
        </Row>
    );
}

export default barangay;