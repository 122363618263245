import * as actionTypes from './actionTypes';
import axios from '../../axios-orders';

// import { transformToFormData } from '../../shared/utility';

export const submitVaccinationStart = () => {
    return {
        type: actionTypes.SUBMIT_VACCINATION_START
    }
};

export const submitVaccinationSuccess = (success) => {
    return {
        type: actionTypes.SUBMIT_VACCINATION_SUCCESS,
        submitSuccess: success
    };
};

export const submitVaccinationFail = (error) => {
    return {
        type: actionTypes.SUBMIT_VACCINATION_FAIL,
        submitError: error
    };
};

export const clearSubmitVaccinationMessage = () => {
    return {
        type: actionTypes.CLEAR_SUBMIT_VACCINATION_MESSAGE,
        submitError: null,
        submitSuccess: null
    };
}

// Middleware
export const submitVaccination = (token, formID, formData) => {
    return dispatch => {
        dispatch(submitVaccinationStart());
        axios.post('/Baliwag_HIMS_VAC/submitVaccination?auth=' + token + '&actionMode=UPDATE&formID=' + formID, formData)
            .then(response => {
                if (!response.data.error) {
                    dispatch(submitVaccinationSuccess(response.data.message));
                } else {
                    dispatch(submitVaccinationFail(response.data.error));
                }
            }).catch(err => {
                console.log("ERR", err);
                dispatch(submitVaccinationFail("Something went wrong!"));
            });
    };
};