import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    members: [],
    pageNumber: 1,
    loading: false,

    form: [],
    submitting: false,
    submitError: null,
    submitSuccess: null,

    currentMember: [],
    memberId: null,
    fetching: false,
    fetchError: null,

    deleting: false,
    deleteError: null,
    deleteSuccess: null,
    
    searchedMember: [],
    searching: false,
    searchError: null,

    hin: null,
    household: [],

    error: null
};

const fetchMembersStart = (state, action) => {
    return updateObject(state, { loading : true, error: null });
};

const fetchMembersSuccess = (state, action) => {
    return updateObject(state, {
        members: action.members,
        form: action.form,
        loading: false,
        error: null,
        hin: action.hin,
        household: action.household
    });
};

const fetchMembersFail = (state, action) => {
    return updateObject(state, { 
        loading : false, 
        error: action.error, 
        form: action.form, 
        hin: action.hin,
        household: action.household
    });
};

const setMemberCurrentPage = (state, action) => {
    return updateObject(state, {pageNumber: action.pageNumber});
}

const updateMemberForm = (state, action) => {
    return updateObject(state, {form: action.form});
}


const submitMemberStart = (state, action) => {
    return updateObject(state, { 
        submitting : true, 
        submitSuccess: null
    });
};

const submitMemberSuccess = (state, action) => {
    return updateObject(state, {
        submitting: false,
        submitSuccess: action.submitSuccess
    });
};

const submitMemberFail = (state, action) => {
    return updateObject(state, { 
        submitting : false,
        submitError: action.error 
    });
};

const clearMemberSubmitError = (state, action) => {
    return updateObject(state, {submitError: action.submitError});
}

const clearMemberSubmitSuccess = (state, action) => {
    return updateObject(state, {submitSuccess: action.submitSuccess});
}

const fetchCurrentMemberStart = (state, action) => {
    return updateObject(state, { 
        fetching : true, 
        fetchError: null, 
        currentMember: [], 
    });
};

const fetchCurrentMemberSuccess = (state, action) => {
    return updateObject(state, {
        fetching : false, 
        fetchError: null, 
        currentMember: action.currentMember, 
        hin: action.hin, 
        form: action.form
    });
};

const fetchCurrentMemberFail = (state, action) => {
    return updateObject(state, { 
        fetching : false, 
        fetchError: action.error
    });
};

const clearMemberFetchError = (state, action) => {
    return updateObject(state, {fetchError: action.fetchError});
}

// DELETE
const deleteMemberStart = (state, action) => {
    return updateObject(state, { 
        deleting : true, 
        deleteError: null,
        deleteSuccess: null
    });
};

const deleteMemberSuccess = (state, action) => {
    return updateObject(state, {
        deleting : false, 
        deleteError: null,
        deleteSuccess: action.deleteSuccess
    });
};

const deleteMemberFail = (state, action) => {
    return updateObject(state, { 
        deleting : false, 
        deleteError: action.error,
        deleteSuccess: null
    });
};

const clearMemberDeleteError = (state, action) => {
    return updateObject(state, {deleteError: action.deleteError});
}

const clearMemberDeleteSuccess = (state, action) => {
    return updateObject(state, {deleteSuccess: action.deleteSuccess});
}


// SEARCH
const searchMemberStart = (state, action) => {
    return updateObject(state, { 
        searching : true, 
        searchError: null,
        searchedMember: []
    });
};

const searchMemberSuccess = (state, action) => {
    let updatedForm = state.form;

    updatedForm = updateObject(updatedForm, {
        ['D_9b_Q']: updateObject(state.form['D_9b_Q'], {
            value: action.searchedMember.last_name,
            valid: true,
            touched: true
        }),
        ['D_9c_Q']: updateObject(state.form['D_9c_Q'], {
            value: action.searchedMember.first_name,
            valid: true,
            touched: true
        }),
        ['D_9d_Q']: updateObject(state.form['D_9d_Q'], {
            value: action.searchedMember.middle_name,
            valid: true,
            touched: true
        }),
        ['D_10_Q']: updateObject(state.form['D_10_Q'], {
            value: action.searchedMember.mobile_no,
            valid: true,
            touched: true
        })
    });

    return updateObject(state, {
        searching : false, 
        searchError: null,
        searchedMember: action.searchedMember,
        form: updatedForm
    });
};

const searchMemberFail = (state, action) => {
    return updateObject(state, { 
        searching : false, 
        searchError: action.error,
        searchedMember: []
    });
};

const clearMemberSearchError = (state, action) => {
    return updateObject(state, {searchError: action.searchError});
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_MEMBERS_START: return fetchMembersStart(state, action);
        case actionTypes.FETCH_MEMBERS_SUCCESS: return fetchMembersSuccess(state, action);
        case actionTypes.FETCH_MEMBERS_FAIL: return fetchMembersFail(state, action);
        case actionTypes.SET_MEMBER_CURRENT_PAGE: return setMemberCurrentPage(state, action);
        case actionTypes.SUBMIT_MEMBER_START: return submitMemberStart(state, action);
        case actionTypes.SUBMIT_MEMBER_SUCCESS: return submitMemberSuccess(state, action);
        case actionTypes.SUBMIT_MEMBER_FAIL: return submitMemberFail(state, action);
        case actionTypes.UPDATE_MEMBER_FORM: return updateMemberForm(state, action);
        case actionTypes.CLEAR_MEMBER_SUBMIT_ERROR: return clearMemberSubmitError(state, action);
        case actionTypes.CLEAR_MEMBER_SUBMIT_SUCCESS: return clearMemberSubmitSuccess(state, action);
        case actionTypes.FETCH_CURRENT_MEMBER_START: return fetchCurrentMemberStart(state, action);
        case actionTypes.FETCH_CURRENT_MEMBER_SUCCESS: return fetchCurrentMemberSuccess(state, action);
        case actionTypes.FETCH_CURRENT_MEMBER_FAIL: return fetchCurrentMemberFail(state, action);
        case actionTypes.CLEAR_MEMBER_FETCH_ERROR: return clearMemberFetchError(state, action);
        case actionTypes.DELETE_MEMBER_START: return deleteMemberStart(state, action);
        case actionTypes.DELETE_MEMBER_SUCCESS: return deleteMemberSuccess(state, action);
        case actionTypes.DELETE_MEMBER_FAIL: return deleteMemberFail(state, action);
        case actionTypes.CLEAR_MEMBER_DELETE_ERROR: return clearMemberDeleteError(state, action);
        case actionTypes.CLEAR_MEMBER_DELETE_SUCCESS: return clearMemberDeleteSuccess(state, action);
        case actionTypes.SEARCH_MEMBER_START: return searchMemberStart(state, action);
        case actionTypes.SEARCH_MEMBER_SUCCESS: return searchMemberSuccess(state, action);
        case actionTypes.SEARCH_MEMBER_FAIL: return searchMemberFail(state, action);
        case actionTypes.CLEAR_MEMBER_SEARCH_ERROR: return clearMemberSearchError(state, action);
        
        default:
            return state;
    }
};

export default reducer;