import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    form: [],
    fetching: false,
    fetchError: null,
    fetchSuccess: null,
};

const fetchFormStart = (state, action) => {
    return updateObject(state, { 
        fetching : true, 
        fetchError: null, 
        fetchSuccess: null, 
        form: [], 
    });
};

const fetchFormSuccess = (state, action) => {
    return updateObject(state, {
        fetching : false, 
        fetchError: null, 
        fetchSuccess: action.fetchSuccess, 
        form: action.form,
    });
};

const fetchFormFail = (state, action) => {
    return updateObject(state, { 
        fetching : false, 
        fetchError: action.fetchError,
    });
};

const clearFormFetchError = (state, action) => {
    return updateObject(state, {fetchError: action.fetchError, fetchSuccess: action.fetchSuccess});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_FORM_START: return fetchFormStart(state, action);
        case actionTypes.FETCH_FORM_SUCCESS: return fetchFormSuccess(state, action);
        case actionTypes.FETCH_FORM_FAIL: return fetchFormFail(state, action);
        case actionTypes.CLEAR_FORM_FETCH_ERROR: return clearFormFetchError(state, action);
        default: return state;
    }
}

export default reducer;