import * as actionTypes from "./actionTypes";
import axios from "../../axios-orders";

export const fetchMembersStart = () => {
    return {
        type: actionTypes.FETCH_MEMBERS_START
    }
};

export const fetchMembersSuccess = (members, form, hin, household) => {
    return {
        type: actionTypes.FETCH_MEMBERS_SUCCESS,
        members: members,
        form: form,
        hin: hin,
        household: household
    };
};

export const fetchMembersFail = (error, form, hin, household) => {
    return {
        type: actionTypes.FETCH_MEMBERS_FAIL,
        error: error,
        form: form,
        hin: hin,
        household: household
    };
};

export const setMemberCurrentPage = (pageNumber) => {
    return {
        type: actionTypes.SET_MEMBER_CURRENT_PAGE,
        pageNumber: pageNumber
    }
};

export const updateMemberForm = (form) => {
    return {
        type: actionTypes.UPDATE_MEMBER_FORM,
        form: form
    }
};

// The Async Action - Middleware
export const fetchMembers = (token, pageNumber, familyId, hin) => {
    return dispatch => {
        dispatch(fetchMembersStart());
        const queryParams = '?auth=' + token + '&pageNumber=' + pageNumber + '&familyId=' + familyId + '&hin=' + hin;
        axios.get('/Baliwag_HIMS/members' + queryParams)
            .then(res => {
                try {
                    // console.log(res.data.error);
                    if (!res.data.error) {
                        dispatch(fetchMembersSuccess(res.data.members, res.data.form, res.data.hin, res.data.household));
                    } else {
                        dispatch(fetchMembersFail(res.data.error, res.data.form, res.data.hin, res.data.household));
                    }
                } catch (error) {
                    console.log("ERROR", error);
                }
                
            })
            .catch(err => {
                console.log("ERR", err);
                dispatch(fetchMembersFail(err.response.data.error));
            });
    };
};



// Submit
export const submitMemberStart = () => {
    return {
        type: actionTypes.SUBMIT_MEMBER_START,
        submitSuccess: null
    };
}

export const submitMemberSuccess = (formData, submitSuccess) => {
    return {
        type: actionTypes.SUBMIT_MEMBER_SUCCESS,
        formData: formData,
        submitSuccess: submitSuccess
    };
}

export const submitMemberFail = (error) => {
    return {
        type: actionTypes.SUBMIT_MEMBER_FAIL,
        error: error
    };
}

export const clearMemberSubmitError = () => {
    return {
        type: actionTypes.CLEAR_MEMBER_SUBMIT_ERROR,
        submitError: null
    };
}

export const clearMemberSubmitSuccess = () => {
    return {
        type: actionTypes.CLEAR_MEMBER_SUBMIT_SUCCESS,
        submitSuccess: null
    };
}

// Middleware
export const submitMemberForm = (formData, token, actionMode, familyId) => {
    return dispatch => {
        dispatch(submitMemberStart());
        axios.post('/Baliwag_HIMS/submitMemberForm?auth=' + token + '&actionMode=' + actionMode + '&familyId=' + familyId, formData)
            .then(response => {
                if (!response.data.error) {
                    dispatch(submitMemberSuccess(formData, response.data.message));
                } else {
                    dispatch(submitMemberFail(response.data.error));
                }
            }).catch(err => {
                console.log("ERR", err);
                dispatch(submitMemberFail("Something went wrong!"));
            });
    };
};


// Fetch Current
export const fetchCurrentMemberSuccess = (currentMember, form, memberId, hin) => {
    return {
        type: actionTypes.FETCH_CURRENT_MEMBER_SUCCESS,
        currentMember: currentMember, 
        form: form, 
        memberId: memberId,
        hin: hin
    };
};

export const fetchCurrentMemberFail = (error) => {
    return {
        type: actionTypes.FETCH_CURRENT_MEMBER_FAIL,
        error: error
    };
};

export const fetchCurrentMemberStart = () => {
    return {
        type: actionTypes.FETCH_CURRENT_MEMBER_START
    }
};

export const clearMemberFetchError = () => {
    return {
        type: actionTypes.CLEAR_MEMBER_FETCH_ERROR,
        fetchError: null
    };
}

export const fetchCurrentMember = (token, memberId) => {
    return dispatch => {
        dispatch(fetchCurrentMemberStart());
        const queryParams = '?auth=' + token + '&memberId=' + memberId;
        axios.get('/Baliwag_HIMS/member' + queryParams)
            .then(res => {
                try {
                    // console.log(res.data.error);
                    if (!res.data.error) {
                        dispatch(fetchCurrentMemberSuccess(res.data.member, res.data.form, res.data.member.member_id, res.data.hin));
                    } else {
                        dispatch(fetchCurrentMemberFail(res.data.error));
                    }
                } catch (error) {
                    console.log("ERROR", error);
                }
            })
            .catch(err => {
                console.log("ERR", err);
                dispatch(fetchCurrentMemberFail(err));
            });
    };
};



// DELETE
export const deleteMemberStart = () => {
    return {
        type: actionTypes.DELETE_MEMBER_START
    }
};

export const deleteMemberSuccess = (deleteSuccess) => {
    return {
        type: actionTypes.DELETE_MEMBER_SUCCESS,
        deleteSuccess: deleteSuccess
    };
};

export const deleteMemberFail = (error) => {
    return {
        type: actionTypes.DELETE_MEMBER_FAIL,
        error: error
    };
};

export const clearMemberDeleteError = () => {
    return {
        type: actionTypes.CLEAR_MEMBER_DELETE_ERROR,
        deleteError: null
    };
}

export const clearMemberDeleteSuccess = () => {
    return {
        type: actionTypes.CLEAR_MEMBER_DELETE_SUCCESS,
        deleteSuccess: null
    };
}

// Middleware
export const deleteMember = (token, memberId) => {
    return dispatch => {
        dispatch(deleteMemberStart());
        const queryParams = '?auth=' + token + '&memberId=' + memberId;
        axios.get('/Baliwag_HIMS/deleteMember' + queryParams)
            .then(res => {
                try {
                    // console.log(res.data.error);
                    if (!res.data.error) {
                        dispatch(deleteMemberSuccess(res.data.success));
                    } else {
                        dispatch(deleteMemberFail(res.data.error));
                    }
                } catch (error) {
                    console.log("ERROR", error);
                    dispatch(deleteMemberFail('Something went wrong'));
                }
            })
            .catch(err => {
                console.log("ERR", err);
                dispatch(deleteMemberFail('Something went wrong'));
            });
    };
};



// Search Member
export const searchMemberStart = () => {
    return {
        type: actionTypes.SEARCH_MEMBER_START
    }
};

export const searchMemberSuccess = (searchSuccess, searchedMember) => {
    return {
        type: actionTypes.SEARCH_MEMBER_SUCCESS,
        searchSuccess: searchSuccess,
        searchedMember: searchedMember
    };
};

export const searchMemberFail = (error) => {
    return {
        type: actionTypes.SEARCH_MEMBER_FAIL,
        error: error
    };
};

export const clearMemberSearchError = () => {
    return {
        type: actionTypes.CLEAR_MEMBER_SEARCH_ERROR,
        searchError: null
    };
}

// Middleware
export const searchMember = (token, qrCode) => {
    return dispatch => {
        dispatch(searchMemberStart());
        const queryParams = '?auth=' + token + '&qrCode=' + qrCode + '&searchBy=QR_CODE';
        axios.get('/Baliwag_HIMS/searchMember' + queryParams)
            .then(res => {
                try {
                    // console.log(res.data.error);
                    if (!res.data.error) {
                        dispatch(searchMemberSuccess(res.data.success, res.data.iamsafeIndividual));
                        // dispatch(updateMemberForm(updatedForm);
                    } else {
                        dispatch(searchMemberFail(res.data.error));
                    }
                } catch (error) {
                    console.log("ERROR", error);
                    dispatch(searchMemberFail('Something went wrong'));
                }
            })
            .catch(err => {
                console.log("ERR", err);
                dispatch(searchMemberFail('Something went wrong'));
            });
    };
};