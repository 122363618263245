import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';

const navigationItems = (props) => {
    let navList = null;

    switch (props.role) {
        case 'ENCODER':
            navList = (
                <ul className={classes.NavigationItems}>
                    {/* <NavigationItem link="/" exact>Burger Builder</NavigationItem> */}
                    {props.isAuthenticated ? <NavigationItem link="/dashboard">Dashboard</NavigationItem> : null}
                    {props.isAuthenticated ? <NavigationItem link="/scanner">New Entry</NavigationItem> : null}
                    {props.isAuthenticated ? <NavigationItem link="/households">Households</NavigationItem> : null}
                    {!props.isAuthenticated 
                        ? <NavigationItem link="/">Login</NavigationItem>
                        : <NavigationItem link="/logout">Logout</NavigationItem>}
                </ul>
            );
            break;
        case 'ENCODER-WAITING-LIST':
            navList = (
                <ul className={classes.NavigationItems}>
                    {/* <NavigationItem link="/" exact>Burger Builder</NavigationItem> */}
                    {props.isAuthenticated ? <NavigationItem link="/dashboard">Dashboard</NavigationItem> : null}
                    {props.isAuthenticated ? <NavigationItem link="/scanner">New Entry</NavigationItem> : null}
                    {props.isAuthenticated ? <NavigationItem link="/households">Households</NavigationItem> : null}
                    {!props.isAuthenticated 
                        ? <NavigationItem link="/">Login</NavigationItem>
                        : <NavigationItem link="/logout">Logout</NavigationItem>}
                </ul>
            );
            break;
        case 'TEAM LEADER':
            navList = (
                <ul className={classes.NavigationItems}>
                    {/* <NavigationItem link="/" exact>Burger Builder</NavigationItem> */}
                    {props.isAuthenticated ? <NavigationItem link="/dashboard">Dashboard</NavigationItem> : null}
                    {props.isAuthenticated ? <NavigationItem link="/households">Households</NavigationItem> : null}
                    {!props.isAuthenticated 
                        ? <NavigationItem link="/">Login</NavigationItem>
                        : <NavigationItem link="/logout">Logout</NavigationItem>}
                </ul>
            );
            break;
        case 'ADMIN':
            navList = (
                <ul className={classes.NavigationItems}>
                    {/* <NavigationItem link="/" exact>Burger Builder</NavigationItem> */}
                    {props.isAuthenticated ? <NavigationItem link="/dashboard">Dashboard</NavigationItem> : null}
                    {props.isAuthenticated ? <NavigationItem link="/dashboardCashAssistance">Cash Assistance</NavigationItem> : null}
                    {props.isAuthenticated ? <NavigationItem link="/households">Households</NavigationItem> : null}
                    {!props.isAuthenticated 
                        ? <NavigationItem link="/">Login</NavigationItem>
                        : <NavigationItem link="/logout">Logout</NavigationItem>}
                </ul>
            );
            break;
        case 'VAC-TRIAGE':
            navList = (
                <ul className={classes.NavigationItems}>
                    {/* <NavigationItem link="/" exact>Burger Builder</NavigationItem> */}
                    {props.isAuthenticated ? <NavigationItem link="/dashboard">Triage</NavigationItem> : null}
                    {!props.isAuthenticated 
                        ? <NavigationItem link="/">Login</NavigationItem>
                        : <NavigationItem link="/logout">Logout</NavigationItem>}
                </ul>
            );
            break;
        case 'VAC-SCREENING':
                navList = (
                    <ul className={classes.NavigationItems}>
                        {/* <NavigationItem link="/" exact>Burger Builder</NavigationItem> */}
                        {props.isAuthenticated ? <NavigationItem link="/dashboard">Screening</NavigationItem> : null}
                        {!props.isAuthenticated 
                            ? <NavigationItem link="/">Login</NavigationItem>
                            : <NavigationItem link="/logout">Logout</NavigationItem>}
                    </ul>
                );
                break;
        case 'VAC-VACCINATION':
            navList = (
                <ul className={classes.NavigationItems}>
                    {/* <NavigationItem link="/" exact>Burger Builder</NavigationItem> */}
                    {props.isAuthenticated ? <NavigationItem link="/dashboard">Vaccination</NavigationItem> : null}
                    {!props.isAuthenticated 
                        ? <NavigationItem link="/">Login</NavigationItem>
                        : <NavigationItem link="/logout">Logout</NavigationItem>}
                </ul>
            );
            break;
        case 'VAC-HOLDING':
            navList = (
                <ul className={classes.NavigationItems}>
                    {/* <NavigationItem link="/" exact>Burger Builder</NavigationItem> */}
                    {props.isAuthenticated ? <NavigationItem link="/dashboard">Holding</NavigationItem> : null}
                    {!props.isAuthenticated 
                        ? <NavigationItem link="/">Login</NavigationItem>
                        : <NavigationItem link="/logout">Logout</NavigationItem>}
                </ul>
            );
            break;
        case 'VAC-ADMIN':
            navList = (
                <ul className={classes.NavigationItems}>
                    {/* <NavigationItem link="/" exact>Burger Builder</NavigationItem> */}
                    {props.isAuthenticated ? <NavigationItem link="/dashboard">Dashboard</NavigationItem> : null}
                    {!props.isAuthenticated 
                        ? <NavigationItem link="/">Login</NavigationItem>
                        : <NavigationItem link="/logout">Logout</NavigationItem>}
                </ul>
            );
            break;
        case 'SUPERADMIN':
            navList = (
                <ul className={classes.NavigationItems}>
                    {/* <NavigationItem link="/" exact>Burger Builder</NavigationItem> */}
                    {props.isAuthenticated ? <NavigationItem link="/dashboard">Dashboard</NavigationItem> : null}
                    {props.isAuthenticated ? <NavigationItem link="/dashboardCashAssistance">Cash Assistance</NavigationItem> : null}
                    {props.isAuthenticated ? <NavigationItem link="/households">Households</NavigationItem> : null}
                    {props.isAuthenticated ? <NavigationItem link="/users">Users</NavigationItem> : null}
                    {!props.isAuthenticated 
                        ? <NavigationItem link="/">Login</NavigationItem>
                        : <NavigationItem link="/logout">Logout</NavigationItem>}
                </ul>
            );
            break;
        case 'CAS-RELEASE':
            navList = (
                <ul className={classes.NavigationItems}>
                    {/* <NavigationItem link="/" exact>Burger Builder</NavigationItem> */}
                    {props.isAuthenticated ? <NavigationItem link="/dashboard">Cash Assistance Dashboard</NavigationItem> : null}
                    {props.isAuthenticated ? <NavigationItem link="/cashAssistance">Cash Assistance</NavigationItem> : null}
                    {props.isAuthenticated ? <NavigationItem link="/dashboardAssistance">Assistance Dashboard</NavigationItem> : null}
                    {props.isAuthenticated ? <NavigationItem link="/assistance">Assistance</NavigationItem> : null}
                    {!props.isAuthenticated 
                        ? <NavigationItem link="/">Login</NavigationItem>
                        : <NavigationItem link="/logout">Logout</NavigationItem>}
                </ul>
            );
            break;
        case 'CAS-GUEST':
            navList = (
                <ul className={classes.NavigationItems}>
                    {/* <NavigationItem link="/" exact>Burger Builder</NavigationItem> */}
                    {props.isAuthenticated ? <NavigationItem link="/dashboard">Dashboard</NavigationItem> : null}
                    {!props.isAuthenticated 
                        ? <NavigationItem link="/">Login</NavigationItem>
                        : <NavigationItem link="/logout">Logout</NavigationItem>}
                </ul>
            );
            break;
        default:
            break;
    }

    return navList;
};

export default navigationItems;
