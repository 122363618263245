import * as actionTypes from "./actionTypes";
import axios from "../../axios-orders";

export const fetchFamiliesStart = () => {
    return {
        type: actionTypes.FETCH_FAMILIES_START
    }
};

export const fetchFamiliesSuccess = (families) => {
    return {
        type: actionTypes.FETCH_FAMILIES_SUCCESS,
        families: families
    };
};

export const fetchFamiliesFail = (error) => {
    return {
        type: actionTypes.FETCH_FAMILIES_FAIL,
        error: error
    };
};

// The Async Action - Middleware
export const fetchFamilies = (token, hin) => {
    return dispatch => {
        dispatch(fetchFamiliesStart());
        const queryParams = '?auth=' + token + '&hin=' + hin;
        axios.get('/Baliwag_HIMS/families' + queryParams)
            .then(res => {
                try {
                    // console.log(res.data.error);
                    if (!res.data.error) {
                        dispatch(fetchFamiliesSuccess(res.data.families, res.data.form));
                    } else {
                        dispatch(fetchFamiliesFail(res.data.error));
                    }
                } catch (error) {
                    console.log("ERROR", error);
                }
                
            })
            .catch(err => {
                console.log("ERR", err);
                dispatch(fetchFamiliesFail(err.response.data.error));
            });
    };
};

// FETCH CURRENT
export const fetchCurrentFamilyStart = () => {
    return {
        type: actionTypes.FETCH_CURRENT_FAMILY_START
    }
};

export const fetchCurrentFamilySuccess = (currentFamily) => {
    return {
        type: actionTypes.FETCH_CURRENT_FAMILY_SUCCESS,
        currentFamily: currentFamily
    };
};

export const fetchCurrentFamilyFail = (error) => {
    return {
        type: actionTypes.FETCH_CURRENT_FAMILY_FAIL,
        error: error
    };
};

export const clearFamilyFetchError = () => {
    return {
        type: actionTypes.CLEAR_FAMILY_FETCH_ERROR,
        fetchError: null
    };
}

// Middleware
export const fetchCurrentFamily = (token, familyId) => {
    return dispatch => {
        dispatch(fetchCurrentFamilyStart());
        const queryParams = '?auth=' + token + '&familyId=' + familyId;
        axios.get('/Baliwag_HIMS/family' + queryParams)
            .then(res => {
                try {
                    // console.log(res.data.error);
                    if (!res.data.error) {
                        dispatch(fetchCurrentFamilySuccess(res.data.family));
                    } else {
                        dispatch(fetchCurrentFamilyFail(res.data.error));
                    }
                } catch (error) {
                    console.log("ERROR", error);
                }
            })
            .catch(err => {
                console.log("ERR", err);
                dispatch(fetchCurrentFamilyFail(err));
            });
    };
};


// DELETE
export const deleteFamilyStart = () => {
    return {
        type: actionTypes.DELETE_FAMILY_START
    }
};

export const deleteFamilySuccess = (deleteSuccess) => {
    return {
        type: actionTypes.DELETE_FAMILY_SUCCESS,
        deleteSuccess: deleteSuccess
    };
};

export const deleteFamilyFail = (error) => {
    return {
        type: actionTypes.DELETE_FAMILY_FAIL,
        error: error
    };
};

export const clearFamilyDeleteError = () => {
    return {
        type: actionTypes.CLEAR_FAMILY_DELETE_ERROR,
        deleteError: null
    };
}

export const clearFamilyDeleteSuccess = () => {
    return {
        type: actionTypes.CLEAR_FAMILY_DELETE_SUCCESS,
        deleteSuccess: null
    };
}

// Middleware
export const deleteFamily = (token, familyId) => {
    return dispatch => {
        dispatch(deleteFamilyStart());
        const queryParams = '?auth=' + token + '&familyId=' + familyId;
        axios.get('/Baliwag_HIMS/deleteFamily' + queryParams)
            .then(res => {
                try {
                    // console.log(res.data.error);
                    if (!res.data.error) {
                        dispatch(deleteFamilySuccess(res.data.success));
                    } else {
                        dispatch(deleteFamilyFail(res.data.error));
                    }
                } catch (error) {
                    console.log("ERROR", error);
                    dispatch(deleteFamilyFail('Something went wrong'));
                }
            })
            .catch(err => {
                console.log("ERR", err);
                dispatch(deleteFamilyFail('Something went wrong'));
            });
    };
};


// CREATE
export const createFamilyStart = () => {
    return {
        type: actionTypes.CREATE_FAMILY_START
    }
};

export const createFamilySuccess = (createSuccess) => {
    return {
        type: actionTypes.CREATE_FAMILY_SUCCESS,
        createSuccess: createSuccess
    };
};

export const createFamilyFail = (error) => {
    return {
        type: actionTypes.CREATE_FAMILY_FAIL,
        error: error
    };
};

export const clearFamilyCreateError = () => {
    return {
        type: actionTypes.CLEAR_FAMILY_CREATE_ERROR,
        createError: null
    };
}

export const clearFamilyCreateSuccess = () => {
    return {
        type: actionTypes.CLEAR_FAMILY_CREATE_SUCCESS,
        createSuccess: null
    };
}

// Middleware
export const createFamily = (token, hin) => {
    return dispatch => {
        dispatch(createFamilyStart());
        const queryParams = '?auth=' + token + '&hin=' + hin;
        axios.get('/Baliwag_HIMS/createFamily' + queryParams)
            .then(res => {
                try {
                    // console.log(res.data.error);
                    if (!res.data.error) {
                        dispatch(createFamilySuccess(res.data.success));
                    } else {
                        dispatch(createFamilyFail(res.data.error));
                    }
                } catch (error) {
                    console.log("ERROR", error);
                    dispatch(createFamilyFail('Something went wrong'));
                }
            })
            .catch(err => {
                console.log("ERR", err);
                dispatch(createFamilyFail('Something went wrong'));
            });
    };
};