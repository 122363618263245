import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    families: [],
    loading: false,
    error: null,

    currentFamily: [],
    fetching: false,
    fetchError: null,

    deleting: false,
    deleteError: null,
    deleteSuccess: null,

    creating: false,
    createError: null,
    createSuccess: null
};

const fetchFamiliesStart = (state, action) => {
    return updateObject(state, { loading : true, error: null });
};

const fetchFamiliesSuccess = (state, action) => {
    return updateObject(state, {
        families: action.families,
        loading: false,
        error: null
    });
};

const fetchFamiliesFail = (state, action) => {
    return updateObject(state, { loading : false, error: action.error });
};

// FETCH CURRENT FAMILY
const fetchCurrentFamilyStart = (state, action) => {
    return updateObject(state, { 
        fetching : true, 
        fetchError: null, 
        currentFamily: [] 
    });
};

const fetchCurrentFamilySuccess = (state, action) => {
    return updateObject(state, {
        fetching : false, 
        fetchError: null, 
        currentFamily: action.currentFamily
    });
};

const fetchCurrentFamilyFail = (state, action) => {
    return updateObject(state, { 
        fetching : false, 
        fetchError: action.error
    });
};

const clearFamilyFetchError = (state, action) => {
    return updateObject(state, {fetchError: action.fetchError});
}

// DELETE
const deleteFamilyStart = (state, action) => {
    return updateObject(state, { 
        deleting : true, 
        deleteError: null,
        deleteSuccess: null
    });
};

const deleteFamilySuccess = (state, action) => {
    return updateObject(state, {
        deleting : false, 
        deleteError: null,
        deleteSuccess: action.deleteSuccess
    });
};

const deleteFamilyFail = (state, action) => {
    return updateObject(state, { 
        deleting : false, 
        deleteError: action.error,
        deleteSuccess: null
    });
};

const clearFamilyDeleteError = (state, action) => {
    return updateObject(state, {deleteError: action.deleteError});
}

const clearFamilyDeleteSuccess = (state, action) => {
    return updateObject(state, {deleteSuccess: action.deleteSuccess});
}


// CREATE
const createFamilyStart = (state, action) => {
    return updateObject(state, { 
        creating : true, 
        createError: null,
        createSuccess: null
    });
};

const createFamilySuccess = (state, action) => {
    return updateObject(state, {
        creating : false, 
        createError: null,
        createSuccess: action.createSuccess
    });
};

const createFamilyFail = (state, action) => {
    return updateObject(state, { 
        creating : false, 
        createError: action.error,
        createSuccess: null
    });
};

const clearFamilyCreateError = (state, action) => {
    return updateObject(state, {createError: action.createError});
}

const clearFamilyCreateSuccess = (state, action) => {
    return updateObject(state, {createSuccess: action.createSuccess});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_FAMILIES_START: return fetchFamiliesStart(state, action);
        case actionTypes.FETCH_FAMILIES_SUCCESS: return fetchFamiliesSuccess(state, action);
        case actionTypes.FETCH_FAMILIES_FAIL: return fetchFamiliesFail(state, action);
        case actionTypes.FETCH_CURRENT_FAMILY_START: return fetchCurrentFamilyStart(state, action);
        case actionTypes.FETCH_CURRENT_FAMILY_SUCCESS: return fetchCurrentFamilySuccess(state, action);
        case actionTypes.FETCH_CURRENT_FAMILY_FAIL: return fetchCurrentFamilyFail(state, action);
        case actionTypes.CLEAR_FAMILY_FETCH_ERROR: return clearFamilyFetchError(state, action);
        case actionTypes.DELETE_FAMILY_START: return deleteFamilyStart(state, action);
        case actionTypes.DELETE_FAMILY_SUCCESS: return deleteFamilySuccess(state, action);
        case actionTypes.DELETE_FAMILY_FAIL: return deleteFamilyFail(state, action);
        case actionTypes.CLEAR_FAMILY_DELETE_ERROR: return clearFamilyDeleteError(state, action);
        case actionTypes.CLEAR_FAMILY_DELETE_SUCCESS: return clearFamilyDeleteSuccess(state, action);
        case actionTypes.CREATE_FAMILY_START: return createFamilyStart(state, action);
        case actionTypes.CREATE_FAMILY_SUCCESS: return createFamilySuccess(state, action);
        case actionTypes.CREATE_FAMILY_FAIL: return createFamilyFail(state, action);
        case actionTypes.CLEAR_FAMILY_CREATE_ERROR: return clearFamilyCreateError(state, action);
        case actionTypes.CLEAR_FAMILY_CREATE_SUCCESS: return clearFamilyCreateSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;