import * as actionTypes from "./actionTypes";
import axios from "../../axios-orders";

export const fetchDashboardStart = () => {
    return {
        type: actionTypes.FETCH_DASHBOARD_START
    }
};

export const fetchDashboardSuccess = (dashboard) => {
    return {
        type: actionTypes.FETCH_DASHBOARD_SUCCESS,
        dashboard: dashboard
    };
};

export const fetchDashboardFail = (error) => {
    return {
        type: actionTypes.FETCH_DASHBOARD_FAIL,
        error: error
    };
};

// The Async Action - Middleware
export const fetchDashboard = (token, barangay) => {
    return dispatch => {
        dispatch(fetchDashboardStart());
        const queryParams = '?auth=' + token + '&barangay=' + barangay;
        axios.get('/Baliwag_HIMS/dashboard' + queryParams)
            .then(res => {
                try {
                    // console.log(res.data.error);
                    if (!res.data.error) {
                        dispatch(fetchDashboardSuccess(res.data.dashboard));
                    } else {
                        dispatch(fetchDashboardFail(res.data.error));
                    }
                } catch (error) {
                    console.log("ERROR", error);
                }
                
            })
            .catch(err => {
                console.log("ERR", err);
                dispatch(fetchDashboardFail(err.response.data.error));
            });
    };
};