import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    dashboard: [],
    loading: false,
    error: null,
};

const fetchDashboardStart = (state, action) => {
    return updateObject(state, { loading : true, error: null });
};

const fetchDashboardSuccess = (state, action) => {
    return updateObject(state, {
        dashboard: action.dashboard,
        loading: false,
        error: null
    });
};

const fetchDashboardFail = (state, action) => {
    return updateObject(state, { loading : false, error: action.error });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_DASHBOARD_START: return fetchDashboardStart(state, action);
        case actionTypes.FETCH_DASHBOARD_SUCCESS: return fetchDashboardSuccess(state, action);
        case actionTypes.FETCH_DASHBOARD_FAIL: return fetchDashboardFail(state, action);
        default:
            return state;
    }
};

export default reducer;