// import { Button } from 'reactstrap';

import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import asyncComponent from './hoc/asyncComponent/asyncComponent';
import HttpsRedirect from 'react-https-redirect';

import Layout from './hoc/Layout/Layout';

import Logout from './containers/Auth/Logout/Logout';
import Dashboard from './containers/Dashboard/Dashboard';
import Scan from './containers/Scanner/Scanner';
import * as actions from "./store/actions/index";
// import { Households } from './containers/Households/Households';

const asyncAuth = asyncComponent(() => {
  return import('./containers/Auth/Auth');
});

const asyncHouseholds = asyncComponent(() => {
  return import('./containers/Households/Households');
});

const asyncFamilies = asyncComponent(() => {
  return import('./containers/Households/Families/Families');
});

const asyncMembers = asyncComponent(() => {
  return import('./containers/Households/Families/Members/Members');
});

const asyncTriage = asyncComponent(() => {
  return import('./containers/Dashboard/Triage/Triage');
});

const asyncScreening = asyncComponent(() => {
  return import('./containers/Dashboard/Screening/Screening');
});

const asyncVaccination = asyncComponent(() => {
  return import('./containers/Dashboard/Vaccination/Vaccination');
});

const asyncHolding = asyncComponent(() => {
  return import('./containers/Dashboard/Holding/Holding');
});

const asyncVaccinationFormHalfPage = asyncComponent(() => {
  return import('./containers/Reports/VaccinationForm/HalfPage/VaccinationForm');
});

const asyncVaccinationFormPrePrinted = asyncComponent(() => {
  return import('./containers/Reports/VaccinationForm/PrePrinted/VaccinationForm');
});

const asyncVaccinationFormPrePrintedBoosters = asyncComponent(() => {
  return import('./containers/Reports/VaccinationForm/PrePrintedBoosters/VaccinationForm');
});

const asyncLinking = asyncComponent(() => {
  return import('./containers/Dashboard/Triage/Linking/Linking');
});

const asyncVaccinee = asyncComponent(() => {
  return import('./containers/Dashboard/Vaccinee/Vaccinee');
});

const asyncDownloadablesVaccination = asyncComponent(() => {
  return import('./containers/Downloadables/Vaccination/Vaccination');
});

const asyncUsers = asyncComponent(() => {
  return import('./containers/Users/Users');
});

const asyncCasRelease = asyncComponent(() => {
  return import('./containers/CashAssistance/CashAssistance');
});

const asyncAssistance = asyncComponent(() => {
  return import('./containers/Assistance/Assistance');
});

const asyncCasReleaseDashboard = asyncComponent(() => {
  return import('./containers/Dashboard/CashAssistance/CashAssistance');
});

const asyncAssReleaseDashboard = asyncComponent(() => {
  return import('./containers/Dashboard/Assistance/Assistance');
});

const asyncAppointment = asyncComponent(() => {
  return import('./containers/Appointment/Appointment');
});

class App extends Component {

  componentDidMount() {
    // This part causes the warning about state update on unmounted component
    this.props.onTryAutoSignup();
  }
  
  render () {
    
    // For unauthenticated users
    let routes = (
      <Switch>
        <Route path="/" component={asyncAuth} />
        <Redirect to="/" />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      if (this.props.role === 'ENCODER') {
        routes = (
          <Switch>
              <Route path="/members/:familyId" component={asyncMembers} />
              <Route path="/families/:hin" component={asyncFamilies} />
              <Route path="/families" component={asyncFamilies} />
              <Route path="/households" component={asyncHouseholds} />
              <Route path="/appointment/:memberId" component={asyncAppointment} />
              <Route path="/scanner" component={Scan} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/logout" component={Logout} />
              <Route path="/" component={asyncAuth} />
              <Redirect to="/" />
          </Switch>
        );
      } else if (this.props.role === 'ENCODER-WAITING-LIST') {
        routes = (
          <Switch>
              <Route path="/members/:familyId" component={asyncMembers} />
              <Route path="/families/:hin" component={asyncFamilies} />
              <Route path="/families" component={asyncFamilies} />
              <Route path="/households" component={asyncHouseholds} />
              <Route path="/appointment/:memberId" component={asyncAppointment} />
              <Route path="/scanner" component={Scan} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/logout" component={Logout} />
              <Route path="/" component={asyncAuth} />
              <Redirect to="/" />
          </Switch>
        );
      } else if (this.props.role === 'TEAM LEADER') {
        routes = (
          <Switch>
              <Route path="/members/:familyId" component={asyncMembers} />
              <Route path="/families/:hin" component={asyncFamilies} />
              <Route path="/families" component={asyncFamilies} />
              <Route path="/appointment/:memberId" component={asyncAppointment} />
              <Route path="/appointment" component={asyncAppointment} />
              <Route path="/households" component={asyncHouseholds} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/logout" component={Logout} />
              <Route path="/" component={asyncAuth} />
              <Redirect to="/" />
          </Switch>
        );
      } else if (this.props.role === 'ADMIN') {
        routes = (
          <Switch>
              <Route path="/members/:familyId" component={asyncMembers} />
              <Route path="/families/:hin" component={asyncFamilies} />
              <Route path="/families" component={asyncFamilies} />
              <Route path="/appointment/:memberId" component={asyncAppointment} />
              <Route path="/households" component={asyncHouseholds} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/dashboardCashAssistance" component={asyncCasReleaseDashboard} />
              <Route path="/logout" component={Logout} />
              <Route path="/" component={asyncAuth} />
              <Redirect to="/" />
          </Switch>
        );
      } else if (this.props.role === 'VAC-TRIAGE') {
        routes = (
          <Switch>
              <Route path="/linking/:linkTo" component={asyncLinking} />
              {/* <Route path="/linking" component={asyncLinking} /> */}
              <Route path="/dashboard" component={asyncTriage} />
              <Route path="/logout" component={Logout} />
              <Route path="/" component={asyncAuth} />
              <Redirect to="/" />
          </Switch>
        );
      } else if (this.props.role === 'VAC-SCREENING') {
        routes = (
          <Switch>
              <Route path="/vaccinee/:qrCode" component={asyncVaccinee} />
              <Route path="/dashboard" component={asyncScreening} />
              <Route path="/logout" component={Logout} />
              <Route path="/" component={asyncAuth} />
              <Redirect to="/" />
          </Switch>
        );
      } else if (this.props.role === 'VAC-VACCINATION') {
        routes = (
          <Switch>
              <Route path="/dashboard" component={asyncVaccination} />
              <Route path="/logout" component={Logout} />
              <Route path="/" component={asyncAuth} />
              <Redirect to="/" />
          </Switch>
        );
      } else if (this.props.role === 'VAC-HOLDING') {
        routes = (
          <Switch>
              <Route path="/vaccinee/:qrCode" component={asyncVaccinee} />
              <Route path="/vaccinationform_halfpage" component={asyncVaccinationFormHalfPage} />
              <Route path="/vaccinationform_preprinted" component={asyncVaccinationFormPrePrinted} />
              <Route path="/vaccinationform_preprinted_boosters" component={asyncVaccinationFormPrePrintedBoosters} />
              <Route path="/dashboard" component={asyncHolding} />
              <Route path="/logout" component={Logout} />
              <Route path="/" component={asyncAuth} />
              <Redirect to="/" />
          </Switch>
        );
      } else if (this.props.role === 'VAC-ADMIN') {
        routes = (
          <Switch>
              <Route path="/dashboard" component={asyncDownloadablesVaccination} />
              <Route path="/logout" component={Logout} />
              <Route path="/" component={asyncAuth} />
              <Redirect to="/" />
          </Switch>
        );
      } else if (this.props.role === 'SUPERADMIN') {
        routes = (
          <Switch>
              <Route path="/members/:familyId" component={asyncMembers} />
              <Route path="/families/:hin" component={asyncFamilies} />
              <Route path="/families" component={asyncFamilies} />
              <Route path="/appointment/:memberId" component={asyncAppointment} />
              <Route path="/households" component={asyncHouseholds} />
              <Route path="/users" component={asyncUsers} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/dashboardCashAssistance" component={asyncCasReleaseDashboard} />
              <Route path="/logout" component={Logout} />
              <Route path="/" component={asyncAuth} />
              <Redirect to="/" />
          </Switch>
        );
      } else if (this.props.role === 'CAS-RELEASE') {
        routes = (
          <Switch>
              <Route path="/dashboard" component={asyncCasReleaseDashboard} />
              <Route path="/cashAssistance" component={asyncCasRelease} />
              <Route path="/dashboardAssistance" component={asyncAssReleaseDashboard} />
              <Route path="/assistance" component={asyncAssistance} />
              <Route path="/logout" component={Logout} />
              <Route path="/" component={asyncAuth} />
              <Redirect to="/" />
          </Switch>
        );
      } else if (this.props.role === 'CAS-GUEST') {
        routes = (
          <Switch>
              <Route path="/dashboard" component={asyncCasReleaseDashboard} />
              <Route path="/logout" component={Logout} />
              <Route path="/" component={asyncAuth} />
              <Redirect to="/" />
          </Switch>
        );
      };
      
    }
    
    return (
      <HttpsRedirect>
        <Layout>
            {routes}
        </Layout>
      </HttpsRedirect>
    );
  }

  
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    role: state.auth.role
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
