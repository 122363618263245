import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    fetching: false,
    fetchError: null,
    fetchSuccess: null,
    vaccinees: [],
    submitting: false,
    submitError: null,
    submitSuccess: null,
    tableContainedWith: '',
    controls: {
        search_first_name: {
            label: 'First Name', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Type the name or part of the name'
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Text',
            disabled: false,
            hidden: false,
            touched: false
        },
        search_middle_name: {
            label: 'Middle Name', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Type the name or part of the name'
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Text',
            disabled: false,
            hidden: false,
            touched: false
        },
        search_last_name: {
            label: 'Last Name', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Type the name or part of the name'
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Text',
            disabled: false,
            hidden: false,
            touched: false
        },
        // search_date_of_birth: {
        //     label: 'Date of Birth', 
        //     elementType: 'input',
        //     elementConfig: {
        //         type: 'date',
        //         placeholder: ''
        //     },
        //     value: '',
        //     validation: {
        //         required: true,
        //     },
        //     valid: true,
        //     valueType: 'Date',
        //     disabled: false,
        //     hidden: false,
        //     touched: false
        // },
        
    }
};

const submitLinkingStart = (state, action) => {
    return updateObject(state, { 
        submitting : true,
        submitSuccess: null,
        submitError: null
    });
};

const submitLinkingSuccess = (state, action) => {
    return updateObject(state, {
        submitting : false, 
        submitSuccess: action.submitSuccess, 
        vaccinees: []
    });
};

const submitLinkingFail = (state, action) => {
    return updateObject(state, { 
        submitting : false, 
        submitError: action.submitError,
    });
};

const clearSubmitLinkingMessage = (state, action) => {
    return updateObject(state, {
        submitError: action.submitError, 
        submitSuccess: action.submitSuccess
    });
}



const fetchVaccineesStart = (state, action) => {
    return updateObject(state, { 
        fetching : true,
        fetchSuccess: null,
        fetchError: null,
        vaccinees: []
    });
};

const fetchVaccineesSuccess = (state, action) => {
    return updateObject(state, {
        fetching : false, 
        fetchSuccess: action.fetchSuccess ,
        vaccinees: action.vaccinees,
        tableContainedWith: action.tableContainedWith
    });
};

const fetchVaccineesFail = (state, action) => {
    return updateObject(state, { 
        fetching : false, 
        fetchError: action.fetchError,
    });
};

const clearFetchVaccineesMessage = (state, action) => {
    return updateObject(state, {
        fetchError: action.fetchError, 
        fetchSuccess: action.fetchSuccess
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SUBMIT_LINKING_START: return submitLinkingStart(state, action);
        case actionTypes.SUBMIT_LINKING_SUCCESS: return submitLinkingSuccess(state, action);
        case actionTypes.SUBMIT_LINKING_FAIL: return submitLinkingFail(state, action);
        case actionTypes.CLEAR_SUBMIT_LINKING_MESSAGE: return clearSubmitLinkingMessage(state, action);

        case actionTypes.FETCH_VACCINEES_START: return fetchVaccineesStart(state, action);
        case actionTypes.FETCH_VACCINEES_SUCCESS: return fetchVaccineesSuccess(state, action);
        case actionTypes.FETCH_VACCINEES_FAIL: return fetchVaccineesFail(state, action);
        case actionTypes.CLEAR_FETCH_VACCINEES_MESSAGE: return clearFetchVaccineesMessage(state, action);

        default: return state;
    }
}

export default reducer;