import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    submitting: false,
    submitError: null,
    submitSuccess: null,
    controls: {
        screening_remarks: {
            label: 'Remarks', 
            elementType: 'textarea',
            elementConfig: {
                type: 'textarea',
                placeholder: 'Enter some remarks'
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: '',
            disabled: false,
            hidden: false,
            touched: false
        },
    }
};

const submitScreeningStart = (state, action) => {
    return updateObject(state, { 
        submitting : true,
        submitSuccess: null,
        submitError: null
    });
};

const submitScreeningSuccess = (state, action) => {
    return updateObject(state, {
        submitting : false, 
        submitSuccess: action.submitSuccess 
    });
};

const submitScreeningFail = (state, action) => {
    return updateObject(state, { 
        submitting : false, 
        submitError: action.submitError,
    });
};

const clearSubmitScreeningMessage = (state, action) => {
    return updateObject(state, {submitError: action.submitError, submitSuccess: action.submitSuccess});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SUBMIT_SCREENING_START: return submitScreeningStart(state, action);
        case actionTypes.SUBMIT_SCREENING_SUCCESS: return submitScreeningSuccess(state, action);
        case actionTypes.SUBMIT_SCREENING_FAIL: return submitScreeningFail(state, action);
        case actionTypes.CLEAR_SUBMIT_SCREENING_MESSAGE: return clearSubmitScreeningMessage(state, action);
        default: return state;
    }
}

export default reducer;