import React from 'react';
import classes from './CivilStatus.module.css';

import { Card, CardText, Row, Col } from 'reactstrap';
import { Bar } from "react-chartjs-2";

const civilStatus = (props) => {
    const labels = Object.entries(props.civilStatusCount).map(([key, value]) => { 
        return key;
    });

    const values = Object.entries(props.civilStatusCount).map(([key, value]) => { 
        return value;
    });
    
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Civil Status',
                backgroundColor: '#f8c291',
                borderColor: '#f8c291',
                borderWidth: 2,
                data: values
            }
        ]
    };

    const options = {
        title:{
            display: true,
            text: 'Civil Status',
            fontSize: 20
        },
        maintainAspectRatio: false
    };
        
    return (
        <Row className={classes.CivilStatus}>
            <Col sm="12">
                <Card body className={classes.Card}>
                    <CardText>
                        <Bar
                            data={data}
                            options={options} 
                            height="400px" 
                            // className={classes.Chart}
                        />
                    </CardText>
                </Card>
            </Col>
        </Row>
    );
}

export default civilStatus;