import React from 'react';

import mainLogo from '../../assets/images/main-logo.png';
import classes from './Logo.module.css';

const logo = (props) => (
    <div className={classes.Logo} style={{height: props.height}}>
        <img src={mainLogo} alt="BHIMS Logo"/>
    </div>
);


export default logo;