import * as actionTypes from './actionTypes';
import axios from '../../axios-orders';

// import { transformToFormData } from '../../shared/utility';

export const submitLinkingStart = () => {
    return {
        type: actionTypes.SUBMIT_LINKING_START
    }
};

export const submitLinkingSuccess = (success) => {
    return {
        type: actionTypes.SUBMIT_LINKING_SUCCESS,
        submitSuccess: success
    };
};

export const submitLinkingFail = (error) => {
    return {
        type: actionTypes.SUBMIT_LINKING_FAIL,
        submitError: error
    };
};

export const clearSubmitLinkingMessage = () => {
    return {
        type: actionTypes.CLEAR_SUBMIT_LINKING_MESSAGE,
        submitError: null,
        submitSuccess: null
    };
}


export const fetchVaccineesStart = () => {
    return {
        type: actionTypes.FETCH_VACCINEES_START
    }
};

export const fetchVaccineesSuccess = (vaccinees, tableContainedWith) => {
    return {
        type: actionTypes.FETCH_VACCINEES_SUCCESS,
        vaccinees: vaccinees,
        tableContainedWith: tableContainedWith
    };
};

export const fetchVaccineesFail = (error) => {
    return {
        type: actionTypes.FETCH_VACCINEES_FAIL,
        fetchError: error
    };
};

export const clearFetchVaccineesMessage = () => {
    return {
        type: actionTypes.CLEAR_FETCH_VACCINEES_MESSAGE,
        fetchError: null,
        fetchSuccess: null
    };
}

// Middleware
export const submitLinking = (token, iamsafeQRCode, idToLink, tableContainedWith) => {
    return dispatch => {
        dispatch(submitLinkingStart());
        axios.post('/Baliwag_HIMS_VAC/linkQRToVaccinee?auth=' + token + '&iamsafeQRCode=' + iamsafeQRCode + '&idToLink=' + idToLink + '&tableContainedWith=' + tableContainedWith, null)
            .then(response => {
                if (!response.data.error) {
                    dispatch(submitLinkingSuccess(response.data.message));
                } else {
                    dispatch(submitLinkingFail(response.data.error));
                }
            }).catch(err => {
                console.log("ERR", err);
                dispatch(submitLinkingFail("Something went wrong!"));
            });
    };
};

export const fetchVaccinees = (token, iamsafeQRCode, formData) => {
    return dispatch => {
        dispatch(fetchVaccineesStart());
        axios.post('/Baliwag_HIMS_VAC/vaccinees?auth=' + token + '&iamsafeQRCode=' + iamsafeQRCode, formData)
            .then(response => {
                if (!response.data.error) {
                    console.log(response.data.vaccinees);
                    dispatch(fetchVaccineesSuccess(response.data.vaccinees, response.data.tableContainedWith));
                } else {
                    dispatch(fetchVaccineesFail(response.data.error));
                }
            }).catch(err => {
                console.log("ERR", err);
                dispatch(fetchVaccineesFail("Something went wrong!"));
            });
    };
};
