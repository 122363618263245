import * as actionTypes from './actionTypes';
import axios from '../../axios-orders';

// import { transformToFormData } from '../../shared/utility';

export const vacScannerStart = () => {
    return {
        type: actionTypes.VAC_SCANNER_START,
        currentQRCode: ''
    };
};

export const vacScannerSuccess = (currentQRCode) => {
    return {
        type: actionTypes.VAC_SCANNER_SUCCESS,
        currentQRCode: currentQRCode
    };
};

export const vacScannerFail = (error, currentQRCode) => {
    return {
        type: actionTypes.VAC_SCANNER_FAIL,
        error: error,
        currentQRCode: currentQRCode
    };
};

export const vacSetScannerCurrentQRCode = (currentQRCode) => {
    return {
        type: actionTypes.VAC_SET_SCANNER_CURRENT_QRCODE,
        currentQRCode: currentQRCode
    };
};

// FETCH CURRENT
export const fetchVaccineeStart = () => {
    return {
        type: actionTypes.FETCH_VACCINEE_START
    }
};

export const fetchVaccineeSuccess = (vaccinee, success, tableContainedWith) => {
    return {
        type: actionTypes.FETCH_VACCINEE_SUCCESS,
        vaccinee: vaccinee,
        fetchSuccess: success, 
        tableContainedWith: tableContainedWith
    };
};

export const fetchVaccineeFail = (error) => {
    return {
        type: actionTypes.FETCH_VACCINEE_FAIL,
        fetchError: error
    };
};

export const clearVaccineeFetchError = () => {
    return {
        type: actionTypes.CLEAR_VACCINEE_FETCH_ERROR,
        fetchError: null,
        fetchSuccess: null
    };
}

// Middleware
export const fetchVaccinee = (token, keyValue, stage, fetchBy) => {
    return dispatch => {
        dispatch(fetchVaccineeStart());
        const queryParams = '?auth=' + token + '&fetchBy=' + fetchBy + '&keyValue=' + keyValue + '&stage=' + stage;
        axios.get('/Baliwag_HIMS_VAC/vaccinee' + queryParams)
            .then(res => {
                try {
                    // console.log(res.data.error);
                    if (!res.data.error) {
                        dispatch(fetchVaccineeSuccess(res.data.vaccinee, res.data.success, res.data.tableContainedWith));
                        dispatch(vacScannerSuccess(res.data.vaccinee.IAMSAFE));
                    } else {
                        dispatch(fetchVaccineeFail(res.data.error));
                        dispatch(vacScannerFail(res.data.error, keyValue));
                    }
                } catch (error) {
                    console.log("ERROR", error);
                }
            })
            .catch(err => {
                console.log("ERR", err);
                dispatch(fetchVaccineeFail(err));
            });
    };
};