import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    households: [],
    pageNumber: 1,
    loading: false,

    form: [],
    submitting: false,
    submitError: null,
    submitSuccess: null,

    currentHousehold: [],
    hin: null,
    fetching: false,
    fetchError: null,
    
    error: null
};

const fetchHouseholdsStart = (state, action) => {
    return updateObject(state, { loading : true, error: null });
};

const fetchHouseholdsSuccess = (state, action) => {
    return updateObject(state, {
        households: action.households,
        form: action.form,
        loading: false,
        error: null
    });
};

const fetchHouseholdsFail = (state, action) => {
    return updateObject(state, { 
        loading : false, 
        error: action.error, 
        form: action.form 
    });
};

const setHouseholdCurrentPage = (state, action) => {
    return updateObject(state, {pageNumber: action.pageNumber});
}

const updateForm = (state, action) => {
    return updateObject(state, {form: action.form});
}


const submitHouseholdStart = (state, action) => {
    return updateObject(state, { 
        submitting : true, 
        submitSuccess: null
    });
};

const submitHouseholdSuccess = (state, action) => {
    return updateObject(state, {
        submitting: false,
        submitSuccess: action.submitSuccess
    });
};

const submitHouseholdFail = (state, action) => {
    return updateObject(state, { 
        submitting : false,
        submitError: action.error 
    });
};

const clearSubmitError = (state, action) => {
    return updateObject(state, {submitError: action.submitError});
}

const clearSubmitSuccess = (state, action) => {
    return updateObject(state, {submitSuccess: action.submitSuccess});
}

const fetchCurrentHouseholdStart = (state, action) => {
    return updateObject(state, { 
        fetching : true, 
        fetchError: null, 
        currentHousehold: [], 
        hin: null
    });
};

const fetchCurrentHouseholdSuccess = (state, action) => {
    return updateObject(state, {
        fetching : false, 
        fetchError: null, 
        currentHousehold: action.currentHousehold, 
        hin: action.hin, 
        form: action.form
    });
};

const fetchCurrentHouseholdFail = (state, action) => {
    return updateObject(state, { 
        fetching : false, 
        fetchError: action.error
    });
};

const clearFetchError = (state, action) => {
    return updateObject(state, {fetchError: action.fetchError});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_HOUSEHOLDS_START: return fetchHouseholdsStart(state, action);
        case actionTypes.FETCH_HOUSEHOLDS_SUCCESS: return fetchHouseholdsSuccess(state, action);
        case actionTypes.FETCH_HOUSEHOLDS_FAIL: return fetchHouseholdsFail(state, action);
        case actionTypes.SET_HOUSEHOLD_CURRENT_PAGE: return setHouseholdCurrentPage(state, action);
        case actionTypes.SUBMIT_HOUSEHOLD_START: return submitHouseholdStart(state, action);
        case actionTypes.SUBMIT_HOUSEHOLD_SUCCESS: return submitHouseholdSuccess(state, action);
        case actionTypes.SUBMIT_HOUSEHOLD_FAIL: return submitHouseholdFail(state, action);
        case actionTypes.UPDATE_FORM: return updateForm(state, action);
        case actionTypes.CLEAR_SUBMIT_ERROR: return clearSubmitError(state, action);
        case actionTypes.CLEAR_SUBMIT_SUCCESS: return clearSubmitSuccess(state, action);
        case actionTypes.FETCH_CURRENT_HOUSEHOLD_START: return fetchCurrentHouseholdStart(state, action);
        case actionTypes.FETCH_CURRENT_HOUSEHOLD_SUCCESS: return fetchCurrentHouseholdSuccess(state, action);
        case actionTypes.FETCH_CURRENT_HOUSEHOLD_FAIL: return fetchCurrentHouseholdFail(state, action);
        case actionTypes.CLEAR_FETCH_ERROR: return clearFetchError(state, action);
        default:
            return state;
    }
};

export default reducer;