import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    submitting: false,
    submitError: null,
    submitSuccess: null,
    controls: {
        // dosage: {
        //     label: 'Dosage', 
        //     elementType: 'select',
        //     elementConfig: {
        //         options: [
        //             { value: '', displayValue: 'Pumili' },
        //             { value: 'Dose 1', displayValue: 'Dose 1' },
        //             { value: 'Dose 2', displayValue: 'Dose 2' },
        //         ]
        //     },
        //     value: '',
        //     validation: {
        //         required: true,
        //     },
        //     valid: false,
        //     valueType: 'Dosage',
        //     disabled: false,
        //     hidden: false
        // },
        vaccination_category: {
            label: 'Category', 
            elementType: 'select',
            elementConfig: {
                options: [
                    { value: '', displayValue: 'Pumili' },
                    { value: 'Health Care Worker', displayValue: 'Health Care Worker' },
                    { value: 'Uniformed Personnel', displayValue: 'Uniformed Personnel' },
                    { value: 'Essential Worker', displayValue: 'Essential Worker' },
                    { value: 'Others', displayValue: 'Others' },
                ]
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            valueType: 'Category',
            disabled: false,
            hidden: false
        },
        vaccination_vaccine_manufacturer: {
            label: 'Manufacturer', 
            elementType: 'select',
            elementConfig: {
                options: [
                    { value: '', displayValue: 'Pumili' },
                    { value: 'Sinovac', displayValue: 'Sinovac' },
                    { value: 'Astra Zeneca', displayValue: 'Astra Zeneca' },
                    { value: 'Gamaleya', displayValue: 'Gamaleya' },
                    { value: 'Janssen', displayValue: 'Janssen' },
                    { value: 'Moderna', displayValue: 'Moderna' },
                    { value: 'Novovax', displayValue: 'Novovax' },
                    { value: 'Pfizer', displayValue: 'Pfizer' },
                    { value: 'SinoPharm', displayValue: 'SinoPharm' }
                ]
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            valueType: 'Manufacturer',
            disabled: false,
            hidden: false
        },
        vaccination_batch_number: {
            label: 'Batch No.', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Batch Number'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            valueType: 'Batch Number',
            disabled: false,
            hidden: false,
            touched: false
        },
        vaccination_lot_number: {
            label: 'Lot No.', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Lot Number'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            valueType: 'LOt Number',
            disabled: false,
            hidden: false,
            touched: false
        },
        vaccination_remarks: {
            label: 'Remarks', 
            elementType: 'textarea',
            elementConfig: {
                type: 'textarea',
                placeholder: 'Enter some remarks'
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: '',
            disabled: false,
            hidden: false,
            touched: false
        }
    }
};

const submitVaccinationStart = (state, action) => {
    return updateObject(state, { 
        submitting : true,
        submitSuccess: null,
        submitError: null
    });
};

const submitVaccinationSuccess = (state, action) => {
    return updateObject(state, {
        submitting : false, 
        submitSuccess: action.submitSuccess 
    });
};

const submitVaccinationFail = (state, action) => {
    return updateObject(state, { 
        submitting : false, 
        submitError: action.submitError,
    });
};

const clearSubmitVaccinationMessage = (state, action) => {
    return updateObject(state, {submitError: action.submitError, submitSuccess: action.submitSuccess});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SUBMIT_VACCINATION_START: return submitVaccinationStart(state, action);
        case actionTypes.SUBMIT_VACCINATION_SUCCESS: return submitVaccinationSuccess(state, action);
        case actionTypes.SUBMIT_VACCINATION_FAIL: return submitVaccinationFail(state, action);
        case actionTypes.CLEAR_SUBMIT_VACCINATION_MESSAGE: return clearSubmitVaccinationMessage(state, action);
        default: return state;
    }
}

export default reducer;