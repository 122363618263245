import React, { Component } from 'react';
import { connect } from "react-redux";
import { Container } from 'reactstrap';

import Aux from '../Aux/Aux';
import classes from './Layout.module.css';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';

class Layout extends Component {
    state = {
        showSideDrawer: false
    };
     
    sideDrawerClosedHandler = () => {
        this.setState({showSideDrawer: false});
    };

    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return {showSideDrawer: !prevState.showSideDrawer};
        });
    }

    render () {
        return (
            // {/* <div>Toolbar, SideDrawer, Backdrop</div> */}
            <Aux>
                <Toolbar 
                    isAuth={this.props.isAuthenticated} 
                    role={this.props.role} 
                    drawerToggleClicked={this.sideDrawerToggleHandler} />
                <SideDrawer 
                    isAuth={this.props.isAuthenticated} 
                    role={this.props.role} 
                    open={this.state.showSideDrawer} closed={this.sideDrawerClosedHandler}/>
                <Container className={classes.Content}>
                    {this.props.children}
                </Container>
                {/* <main className={classes.Content}>
                </main> */}
            </Aux>
        );
    }
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        role: state.auth.role
    };
};

export default connect(mapStateToProps, null)(Layout);