import * as actionTypes from "./actionTypes";
import axios from "../../axios-orders";

export const fetchHouseholdsSuccess = (households, form) => {
    return {
        type: actionTypes.FETCH_HOUSEHOLDS_SUCCESS,
        households: households,
        form: form
    };
};

export const fetchHouseholdsFail = (error, form) => {
    return {
        type: actionTypes.FETCH_HOUSEHOLDS_FAIL,
        form: form,
        error: error
    };
};

export const fetchHouseholdsStart = () => {
    return {
        type: actionTypes.FETCH_HOUSEHOLDS_START
    }
};

export const setHouseholdCurrentPage = (pageNumber) => {
    return {
        type: actionTypes.SET_HOUSEHOLD_CURRENT_PAGE,
        pageNumber: pageNumber
    }
};

export const updateForm = (form) => {
    return {
        type: actionTypes.UPDATE_FORM,
        form: form
    }
};

// The Async Action - Middleware
export const fetchHouseholds = (token, pageNumber, currentQRCode, filterByStatus) => {
    return dispatch => {
        dispatch(fetchHouseholdsStart());
        const queryParams = '?auth=' + token + '&pageNumber=' + pageNumber + '&currentQRCode=' + currentQRCode + '&filterByStatus=' + filterByStatus;
        axios.get('/Baliwag_HIMS/households' + queryParams)
            .then(res => {
                try {
                    // console.log(res.data.error);
                    if (!res.data.error) {
                        dispatch(fetchHouseholdsSuccess(res.data.households, res.data.form));
                    } else {
                        dispatch(fetchHouseholdsFail(res.data.error, res.data.form));
                    }
                } catch (error) {
                    console.log("ERROR", error);
                }
                
            })
            .catch(err => {
                console.log("ERR", err);
                dispatch(fetchHouseholdsFail(err.response.data.error, []));
            });
    };
};




export const submitHouseholdStart = () => {
    return {
        type: actionTypes.SUBMIT_HOUSEHOLD_START,
        submitSuccess: null
    };
}

export const submitHouseholdSuccess = (formData, submitSuccess) => {
    return {
        type: actionTypes.SUBMIT_HOUSEHOLD_SUCCESS,
        formData: formData,
        submitSuccess: submitSuccess
    };
}

export const submitHouseholdFail = (error) => {
    return {
        type: actionTypes.SUBMIT_HOUSEHOLD_FAIL,
        error: error
    };
}

export const clearSubmitError = () => {
    return {
        type: actionTypes.CLEAR_SUBMIT_ERROR,
        submitError: null
    };
}

export const clearSubmitSuccess = () => {
    return {
        type: actionTypes.CLEAR_SUBMIT_SUCCESS,
        submitSuccess: null
    };
}

// Middleware
export const submitHouseholdForm = (formData, token, actionMode) => {
    return dispatch => {
        dispatch(submitHouseholdStart());
        axios.post('/Baliwag_HIMS/submitHouseholdForm?auth=' + token + '&actionMode=' + actionMode, formData)
            .then(response => {
                if (!response.data.error) {
                    dispatch(submitHouseholdSuccess(formData, response.data.message));
                } else {
                    dispatch(submitHouseholdFail(response.data.error));
                }
            }).catch(err => {
                console.log("ERR", err);
                dispatch(submitHouseholdFail("Something went wrong!"));
            });
    };
};



export const fetchCurrentHouseholdSuccess = (currentHousehold, form, hin) => {
    return {
        type: actionTypes.FETCH_CURRENT_HOUSEHOLD_SUCCESS,
        currentHousehold: currentHousehold, 
        hin: hin,
        form: form
    };
};

export const fetchCurrentHouseholdFail = (error) => {
    return {
        type: actionTypes.FETCH_CURRENT_HOUSEHOLD_FAIL,
        error: error
    };
};

export const fetchCurrentHouseholdStart = () => {
    return {
        type: actionTypes.FETCH_CURRENT_HOUSEHOLD_START
    }
};

export const clearFetchError = () => {
    return {
        type: actionTypes.CLEAR_FETCH_ERROR,
        fetchError: null
    };
}

export const fetchCurrentHousehold = (token, householdIdentificationNumber) => {
    return dispatch => {
        dispatch(fetchCurrentHouseholdStart());
        const queryParams = '?auth=' + token + '&householdIdentificationNumber=' + householdIdentificationNumber;
        axios.get('/Baliwag_HIMS/household' + queryParams)
            .then(res => {
                try {
                    // console.log(res.data.error);
                    if (!res.data.error) {
                        dispatch(fetchCurrentHouseholdSuccess(res.data.household, res.data.form, res.data.household.A_II_household_identification_number));
                    } else {
                        dispatch(fetchCurrentHouseholdFail(res.data.error));
                    }
                } catch (error) {
                    console.log("ERROR", error);
                }
            })
            .catch(err => {
                console.log("ERR", err);
                dispatch(fetchCurrentHouseholdFail(err));
            });
    };
};