import * as actionTypes from './actionTypes';
// import axios from '../../axios-orders';

// import { transformToFormData } from '../../shared/utility';

export const scannerStart = () => {
    return {
        type: actionTypes.SCANNER_START
    };
};

export const scannerSuccess = (currentQRCode, showModal, actionMode) => {
    return {
        type: actionTypes.SCANNER_SUCCESS,
        currentQRCode: currentQRCode,
        showModal: showModal,
        actionMode: actionMode
    };
};

export const scannerFail = (error) => {
    return {
        type: actionTypes.SCANNER_FAIL,
        error: error
    };
};

export const setScannerCurrentQRCode = (currentQRCode, actionMode) => {
    return {
        type: actionTypes.SET_SCANNER_CURRENT_QRCODE,
        currentQRCode: currentQRCode,
        actionMode: actionMode
    };
};

export const toggleScannerModal = (showModal) => {
    return {
        type: actionTypes.TOGGLE_SCANNER_MODAL,
        showModal: showModal
    };
};