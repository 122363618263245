import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom'

// Higher Order Components
import Aux from '../../hoc/Aux/Aux';
// import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
// import axios from '../../axios-orders';

// Redux
import * as actions from '../../store/actions/index';

// UI
import Spinner from '../../components/UI/Spinner/Spinner';

// Icons
// import { IconContext } from "react-icons";
import { BsHouse, BsPerson } from "react-icons/bs";
import { RiGroup2Line } from "react-icons/ri";
import {Row, Col } from 'reactstrap';

// Styles
// import classes from './Dashboard.module.css';

// Components
import CountWidget from '../../components/Dashboard/CountWidget/CountWidget';
import Sex from '../../components/Dashboard/Sex/Sex';
import Network from '../../components/Dashboard/Network/Network';
import AgeBracket from '../../components/Dashboard/AgeBracket/AgeBracket';
import BirthRegistration from '../../components/Dashboard/BirthRegistration/BirthRegistration';
import CivilStatus from '../../components/Dashboard/CivilStatus/CivilStatus';
import Employment from '../../components/Dashboard/Employment/Employment';
import WorkStatus from '../../components/Dashboard/WorkStatus/WorkStatus';
import WorkerType from '../../components/Dashboard/WorkerType/WorkerType';
import IncomeRange from '../../components/Dashboard/IncomeRange/IncomeRange';
import EducationalStatus from '../../components/Dashboard/EducationalStatus/EducationalStatus';
import EducationalAttainment from '../../components/Dashboard/EducationalAttainment/EducationalAttainment';
import VaccineAcceptance from '../../components/Dashboard/VaccineAcceptance/VaccineAcceptance';
import VaccineRejection from '../../components/Dashboard/VaccineRejection/VaccineRejection';
import Comorbidity from '../../components/Dashboard/Comorbidity/Comorbidity';
import Pregnancy from '../../components/Dashboard/Pregnancy/Pregnancy';
import SoloParent from '../../components/Dashboard/SoloParent/SoloParent';
import PWD from '../../components/Dashboard/PWD/PWD';
import Disability from '../../components/Dashboard/Disability/Disability';
import SeniorCitizen from '../../components/Dashboard/SeniorCitizen/SeniorCitizen';
import Barangays from '../../components/Controls/Filter/Barangay/Barangay';

export class Dashboard extends Component {

    state = {
        open: false,
        role: "",

        barangay: 'ALL'
    };

    componentDidMount() {
        if (this.props.role) {
            this.setState({open: true, role: this.props.role});
        }
        this.props.onFetchDashboard(this.props.token, this.state.barangay);
    }

    changedBarangayHandler = (event) => {
        this.props.onFetchDashboard(this.props.token, event.target.value);
        this.setState({barangay: event.target.value});
    }
    
    render() {
        let dashboard = <Spinner />;
        let dashboardAdmin = null;
        let filterByBarangay = null;
        let header = null;
        const allowedRoles = ["ENCODER", 'ENCODER-WAITING-LIST', "TEAM LEADER", "ADMIN", "SUPERADMIN", "CAS-GUEST"];
        
        if (this.state.open) {
            if (allowedRoles.includes(this.state.role)) {
                header = (
                    <Row>
                        <Col sm="12">
                            <h3>Welcome {this.state.role}!</h3>
                        </Col>
                    </Row>
                );

                if (!this.props.loading) {
                    dashboard = (
                        <Row>
                            <CountWidget 
                                icon={<BsHouse />} 
                                count={this.props.dashboard.householdsCount} 
                                label="Total Encoded Households" 
                                colSize="6" 
                                />
                            <CountWidget 
                                icon={<RiGroup2Line />} 
                                count={this.props.dashboard.familiesCount} 
                                label="Total Encoded Families" 
                                colSize="6" 
                                />
                            <CountWidget 
                                icon={<BsPerson />} 
                                count={this.props.dashboard.membersCount} 
                                label="Total Encoded Members" 
                                colSize="12" 
                                />
                        </Row>
                    );

                    if (['ADMIN', 'SUPERADMIN'].includes(this.state.role)) {
                        filterByBarangay = <Barangays barangays={this.props.dashboard.admin.barangays} onChange={(event) => this.changedBarangayHandler(event)} selectedBarangay={this.state.barangay} />;

                        dashboardAdmin = (
                            <Aux>
                                <Row>
                                    <Col xs="12">
                                        <AgeBracket bracket={this.props.dashboard.admin.ageBracketCount} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="6">
                                        <Sex male={this.props.dashboard.admin.sexCount.Male} female={this.props.dashboard.admin.sexCount.Female} />
                                    </Col>
                                    <Col sm="6">
                                        <BirthRegistration registered={this.props.dashboard.admin.birthRegistrationCount.registered} notRegistered={this.props.dashboard.admin.birthRegistrationCount.notRegistered}/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <CivilStatus civilStatusCount={this.props.dashboard.admin.civilStatusCount} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs="12">
                                        <EducationalAttainment educationalAttainmentCount={this.props.dashboard.admin.educationalAttainmentCount} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="6">
                                        <EducationalStatus yes={this.props.dashboard.admin.educationalStatusCount.Oo}  no={this.props.dashboard.admin.educationalStatusCount.Hindi}  belowThreeYearsOld={this.props.dashboard.admin.educationalStatusCount.BelowThreeYearsOld}/>
                                    </Col>
                                    <Col sm="6">
                                        <Employment employed={this.props.dashboard.admin.employmentCount.employed} notEmployed={this.props.dashboard.admin.employmentCount.notEmployed}/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="6">
                                        <WorkStatus workStatusCount={this.props.dashboard.admin.workStatusCount} />
                                    </Col>
                                    <Col sm="6">
                                        <WorkerType workerTypeCount={this.props.dashboard.admin.workerTypeCount} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12">
                                        <IncomeRange incomeRangeCount={this.props.dashboard.admin.incomeRangeCount} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="6">
                                        <VaccineAcceptance yes={this.props.dashboard.admin.vaccineAcceptanceCount.Oo} no={this.props.dashboard.admin.vaccineAcceptanceCount.Hindi} notSure={this.props.dashboard.admin.vaccineAcceptanceCount.HindiTiyak}/>
                                    </Col>
                                    <Col sm="6">
                                        <VaccineRejection complacency={this.props.dashboard.admin.vaccineRejectionCount.Complacency} confidence={this.props.dashboard.admin.vaccineRejectionCount.Confidence} convenience={this.props.dashboard.admin.vaccineRejectionCount.Convenience}/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Comorbidity comorbidityCount={this.props.dashboard.admin.comorbidityCount}/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="6">
                                        <Pregnancy pregnant={this.props.dashboard.admin.pregnantCount.Oo} notPregnant={this.props.dashboard.admin.pregnantCount.Hindi}/>
                                    </Col>
                                    <Col sm="6">
                                        <SoloParent withID={this.props.dashboard.admin.soloParentCount.withID} withoutID={this.props.dashboard.admin.soloParentCount.withoutID} totalMembers={this.props.dashboard.admin.totalMembers}/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="6">
                                        <PWD withID={this.props.dashboard.admin.pwdCount.withID} withoutID={this.props.dashboard.admin.pwdCount.withoutID} totalMembers={this.props.dashboard.admin.totalMembers}/>
                                    </Col>
                                    <Col sm="6">
                                        <Disability disabilityCount={this.props.dashboard.admin.disabilityCount}/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="6">
                                        <SeniorCitizen withID={this.props.dashboard.admin.seniorCount.withID} withoutID={this.props.dashboard.admin.seniorCount.withoutID} totalMembers={this.props.dashboard.admin.totalMembers}/>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12">
                                        <Network counts={this.props.dashboard.admin.networkCount} /> 
                                    </Col>
                                </Row>
                            </Aux>
                        );
                    };
                    
                }
                
            } else {
               
            }
        }

        return (
            <Aux>
                {header}
                {filterByBarangay}
                {dashboard} 
                {dashboardAdmin} 
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {    
        isAuthenticated: state.auth.token !== null,
        token: state.auth.token,
        role: state.auth.role,

        dashboard: state.dashboard.dashboard,
        loading: state.dashboard.loading,
        error: state.dashboard.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchDashboard: (token, barangay) => dispatch(actions.fetchDashboard(token, barangay)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);