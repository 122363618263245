import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    currentQRCode: '',
    error: null,

    vaccinee: [],
    fetching: false,
    fetchError: null,
    fetchSuccess: null,
    tableContainedWith: null
};

const vacScannerStart = (state, action) => {
    return updateObject(state, {
        currentQRCode: '',
        error: null,
    });
}

const vacScannerSuccess = (state, action) => {
    return updateObject(state, {
        currentQRCode: action.currentQRCode,
        error: null,
    });
}

const vacScannerFail = (state, action) => {
    return updateObject(state, {
        currentQRCode: action.currentQRCode,
        error: action.error,
    });
}

const vacSetScannerCurrentQRCode = (state, action) => {
    return updateObject(state, {currentQRCode: action.currentQRCode});
}

const fetchVaccineeStart = (state, action) => {
    return updateObject(state, { 
        fetching : true, 
        fetchError: null, 
        fetchSuccess: null, 
        vaccinee: [], 
        tableContainedWith: null
    });
};

const fetchVaccineeSuccess = (state, action) => {
    return updateObject(state, {
        fetching : false, 
        fetchError: null, 
        fetchSuccess: action.fetchSuccess, 
        vaccinee: action.vaccinee,
        tableContainedWith: action.tableContainedWith
    });
};

const fetchVaccineeFail = (state, action) => {
    return updateObject(state, { 
        fetching : false, 
        fetchError: action.fetchError,
    });
};

const clearVaccineeFetchError = (state, action) => {
    return updateObject(state, {fetchError: action.fetchError, fetchSuccess: action.fetchSuccess});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.VAC_SCANNER_START: return vacScannerStart(state, action);
        case actionTypes.VAC_SCANNER_SUCCESS: return vacScannerSuccess(state, action);
        case actionTypes.VAC_SCANNER_FAIL: return vacScannerFail(state, action);
        case actionTypes.VAC_SET_SCANNER_CURRENT_QRCODE: return vacSetScannerCurrentQRCode(state, action);
        case actionTypes.FETCH_VACCINEE_START: return fetchVaccineeStart(state, action);
        case actionTypes.FETCH_VACCINEE_SUCCESS: return fetchVaccineeSuccess(state, action);
        case actionTypes.FETCH_VACCINEE_FAIL: return fetchVaccineeFail(state, action);
        case actionTypes.CLEAR_VACCINEE_FETCH_ERROR: return clearVaccineeFetchError(state, action);
        default: return state;
    }
}

export default reducer;