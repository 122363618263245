import React from 'react';
import classes from './EducationalStatus.module.css';

import { Card, CardText, Row, Col } from 'reactstrap';
import { Doughnut } from "react-chartjs-2";
import { IconContext } from "react-icons";
import { FaSchool, FaHome, FaBabyCarriage } from "react-icons/fa";

import { numberFormat } from '../../../shared/utility';

const educationalStatus = (props) => {
    const data = {
        labels: ['Attending School', 'Not Attending School', 'Below 3 years old'],
        datasets: [
            {
                label: "Educational Status",
                data: [props.yes, props.no, props.belowThreeYearsOld],
                fill: true,
                backgroundColor: ["#f8c291", "#6a89cc", "#ff6b6b"],
                borderColor: ["#f8c291", "#6a89cc", "#ff6b6b"]
            },
            // {
            //     label: "Second dataset",
            //     data: [33, 25, 35, 51, 54, 76],
            //     fill: false,
            //     borderColor: "#742774"
            // }
        ]
    };

    const options = {
        title:{
            display: true,
            text: 'Educational Status',
            fontSize: 20
        }
    };
        
    return (
        <Row className={classes.EducationalStatus}>
            <Col sm="12">
                <Card body className={classes.Card}>
                    <CardText>
                        <Doughnut data={data} options={options}/>
                    </CardText>
                    <Row>
                        <Col xs="4" className={classes.YesCount}>
                            <IconContext.Provider value={{ className: [classes.CountIcon, classes.CountIconYes].join(' ')}}>
                                <FaSchool/>
                            </IconContext.Provider> 
                            {numberFormat(props.yes, 0, '', ',')}
                            <br></br>
                            <small> {numberFormat(((parseInt(props.yes)/(parseInt(props.yes) + parseInt(props.no) + parseInt(props.belowThreeYearsOld))) * 100), 2, '.', ',')}%</small>
                        </Col>
                        <Col xs="4" className={classes.NoCount}>
                            <IconContext.Provider value={{ className: [classes.CountIcon, classes.CountIconNo].join(' ')}}>
                                <FaHome/>
                            </IconContext.Provider> 
                            {numberFormat(props.no, 0, '', ',')}
                            <br></br>
                            <small> {numberFormat(((parseInt(props.no)/(parseInt(props.yes) + parseInt(props.no) + parseInt(props.belowThreeYearsOld))) * 100), 2, '.', ',')}%</small>
                        </Col>
                        <Col xs="4" className={classes.BelowThreeYearsOldCount}>
                            <IconContext.Provider value={{ className: [classes.CountIcon, classes.CountIconBelowThreeYearsOld].join(' ')}}>
                                <FaBabyCarriage/>
                            </IconContext.Provider> 
                            {numberFormat(props.belowThreeYearsOld, 0, '', ',')}
                            <br></br>
                            <small> {numberFormat(((parseInt(props.belowThreeYearsOld)/(parseInt(props.yes) + parseInt(props.no) + parseInt(props.belowThreeYearsOld))) * 100), 2, '.', ',')}%</small>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}

export default educationalStatus;