import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    submitting: false,
    submitError: null,
    submitSuccess: null,
    controls: {
        'FIRST NAME': {
            label: 'First Name', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Type the name or part of the name'
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Text',
            disabled: false,
            hidden: false,
            touched: false
        },
        'MIDDLE NAME': {
            label: 'Middle Name', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Type the name or part of the name'
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Text',
            disabled: false,
            hidden: false,
            touched: false
        },
        'LAST NAME': {
            label: 'Last Name', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Type the name or part of the name'
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Text',
            disabled: false,
            hidden: false,
            touched: false
        },
        'CONTACT NUMBER': {
            label: 'Contact Number', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: ''
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Text',
            disabled: false,
            hidden: false,
            touched: false
        },
        'Kasarian': {
            label: 'Sex', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Lalaki or Babae / Male or Female'
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Text',
            disabled: false,
            hidden: false,
            touched: false
        },
        'Petsa ng Kapanganakan': {
            label: 'Date of Birth', 
            elementType: 'input',
            elementConfig: {
                type: 'date',
                placeholder: ''
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Text',
            disabled: false,
            hidden: false,
            touched: false
        },
        'Itinalang PhilHealth ID/Insurance ID': {
            label: 'Philhealth ID / Insurance ID', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: ''
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Text',
            disabled: false,
            hidden: false,
            touched: false
        },
        'Allergies': {
            label: 'Allergies', 
            elementType: 'checkbox',
            elementConfig: {
                options: [
                    { value: 'Drug', displayValue: 'Drug' },
                    { value: 'Food', displayValue: 'Food' },
                    { value: 'Insect', displayValue: 'Insect' },
                    { value: 'Latex', displayValue: 'Latex' },
                    { value: 'Mold', displayValue: 'Mold' },
                    { value: 'Pet', displayValue: 'Pet' },
                    { value: 'Pollen', displayValue: 'Pollen' },
                ]
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Choice',
            disabled: false,
            hidden: false,
            touched: false
        },
        'Kasalukuyang karamdaman': {
            label: 'Kasalukuyang karamdaman', 
            elementType: 'checkbox',
            elementConfig: {
                options: [
                    { value: 'Hypertension', displayValue: 'Hypertension' },
                    { value: 'Heart Disease', displayValue: 'Heart Disease' },
                    { value: 'Kidney Disease', displayValue: 'Kidney Disease' },
                    { value: 'Diabetes Melitus', displayValue: 'Diabetes Melitus' },
                    { value: 'Bronchial Asthma', displayValue: 'Bronchial Asthma' },
                    { value: 'Immuno Deficiency', displayValue: 'Immuno Deficiency' },
                    { value: 'Cancer', displayValue: 'Cancer' },
                ]
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Choice',
            disabled: false,
            hidden: false,
            touched: false
        },
        'address_line_1': {
            label: 'Adress Line 1', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: ''
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Text',
            disabled: false,
            hidden: false,
            touched: false
        },
        'address_line_2': {
            label: 'Adress Line 2', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: ''
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Text',
            disabled: false,
            hidden: false,
            touched: false
        },
        'address_line_3': {
            label: 'Adress Line 3', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: ''
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Text',
            disabled: false,
            hidden: false,
            touched: false
        },
        'address_line_4': {
            label: 'Adress Line 4', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: ''
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Text',
            disabled: false,
            hidden: false,
            touched: false
        },
        // search_date_of_birth: {
        //     label: 'Date of Birth', 
        //     elementType: 'input',
        //     elementConfig: {
        //         type: 'date',
        //         placeholder: ''
        //     },
        //     value: '',
        //     validation: {
        //         required: true,
        //     },
        //     valid: true,
        //     valueType: 'Date',
        //     disabled: false,
        //     hidden: false,
        //     touched: false
        // },
        
    }
};

const submitVaccineeProfileStart = (state, action) => {
    return updateObject(state, { 
        submitting : true,
        submitSuccess: null,
        submitError: null
    });
};

const submitVaccineeProfileSuccess = (state, action) => {
    return updateObject(state, {
        submitting : false, 
        submitSuccess: action.submitSuccess
    });
};

const submitVaccineeProfileFail = (state, action) => {
    return updateObject(state, { 
        submitting : false, 
        submitError: action.submitError,
    });
};

const clearSubmitVaccineeProfileMessage = (state, action) => {
    return updateObject(state, {
        submitError: action.submitError, 
        submitSuccess: action.submitSuccess
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SUBMIT_VACCINEE_PROFILE_START: return submitVaccineeProfileStart(state, action);
        case actionTypes.SUBMIT_VACCINEE_PROFILE_SUCCESS: return submitVaccineeProfileSuccess(state, action);
        case actionTypes.SUBMIT_VACCINEE_PROFILE_FAIL: return submitVaccineeProfileFail(state, action);
        case actionTypes.CLEAR_SUBMIT_VACCINEE_PROFILE_MESSAGE: return clearSubmitVaccineeProfileMessage(state, action);

        default: return state;
    }
}

export default reducer;