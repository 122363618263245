import * as actionTypes from './actionTypes';
import axios from '../../axios-orders';

// import { transformToFormData } from '../../shared/utility';

export const submitScreeningStart = () => {
    return {
        type: actionTypes.SUBMIT_SCREENING_START
    }
};

export const submitScreeningSuccess = (success) => {
    return {
        type: actionTypes.SUBMIT_SCREENING_SUCCESS,
        submitSuccess: success
    };
};

export const submitScreeningFail = (error) => {
    return {
        type: actionTypes.SUBMIT_SCREENING_FAIL,
        submitError: error
    };
};

export const clearSubmitScreeningMessage = () => {
    return {
        type: actionTypes.CLEAR_SUBMIT_SCREENING_MESSAGE,
        submitError: null,
        submitSuccess: null
    };
}

// Middleware
export const submitScreening = (token, formID, formData) => {
    return dispatch => {
        dispatch(submitScreeningStart());
        axios.post('/Baliwag_HIMS_VAC/submitScreening?auth=' + token + '&actionMode=UPDATE&formID=' + formID, formData)
            .then(response => {
                if (!response.data.error) {
                    dispatch(submitScreeningSuccess(response.data.message));
                } else {
                    dispatch(submitScreeningFail(response.data.error));
                }
            }).catch(err => {
                console.log("ERR", err);
                dispatch(submitScreeningFail("Something went wrong!"));
            });
    };
};