import React from 'react';
import classes from './VaccineAcceptance.module.css';

import { Card, CardText, Row, Col } from 'reactstrap';
import { Pie } from "react-chartjs-2";
import { IconContext } from "react-icons";
import { BsQuestionCircle } from "react-icons/bs";
import { FiCheckCircle } from "react-icons/fi";
import { FaRegTimesCircle } from "react-icons/fa";

import { numberFormat } from '../../../shared/utility';

const vaccineAcceptance = (props) => {
    const data = {
        labels: ["Yes", "No", "Not Sure"],
        datasets: [
            {
                label: "Vaccine Acceptance",
                data: [props.yes, props.no, props.notSure],
                fill: true,
                backgroundColor: ["#f8c291", "#6a89cc", "#576574"],
                borderColor: ["#f8c291", "#6a89cc", "#576574"]
            }
        ]
    };

    const options = {
        title:{
            display: true,
            text: 'Vaccine Acceptance',
            fontSize: 20
        }
    };
        
    return (
        <Row className={classes.VaccineAcceptance}>
            <Col sm="12">
                <Card body className={classes.Card}>
                    <CardText>
                        <Pie data={data} options={options}/>
                    </CardText>
                    <Row>
                        <Col xs="4" className={classes.YesCount}>
                            <IconContext.Provider value={{ className: [classes.CountIcon, classes.CountIconYes].join(' ')}}>
                                <FiCheckCircle/>
                            </IconContext.Provider> 
                            {numberFormat(props.yes, 0, '', ',')}
                            <br></br>
                            <small> {numberFormat(((parseInt(props.yes)/(parseInt(props.yes) + parseInt(props.no) + parseInt(props.notSure))) * 100), 2, '.', ',')}%</small>
                        </Col>
                        <Col xs="4" className={classes.NoCount}>
                            <IconContext.Provider value={{ className: [classes.CountIcon, classes.CountIconNo].join(' ')}}>
                                <FaRegTimesCircle/>
                            </IconContext.Provider> 
                            {numberFormat(props.no, 0, '', ',')}
                            <br></br>
                            <small> {numberFormat(((parseInt(props.no)/(parseInt(props.yes) + parseInt(props.no) + parseInt(props.notSure))) * 100), 2, '.', ',')}%</small>
                        </Col>
                        <Col xs="4" className={classes.NotSureCount}>
                            <IconContext.Provider value={{ className: [classes.CountIcon, classes.CountIconNotSure].join(' ')}}>
                                <BsQuestionCircle/>
                            </IconContext.Provider> 
                            {numberFormat(props.notSure, 0, '', ',')}
                            <br></br>
                            <small> {numberFormat(((parseInt(props.notSure)/(parseInt(props.yes) + parseInt(props.no) + parseInt(props.notSure))) * 100), 2, '.', ',')}%</small>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}

export default vaccineAcceptance;