import * as actionTypes from './actionTypes';
import axios from '../../axios-orders';

import { transformToFormData } from '../../shared/utility';

// import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, userId, role, vacFacility, barangay) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId,
        role: role,
        vacFacility: vacFacility,
        barangay: barangay
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    localStorage.removeItem('role');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

// Async Code
export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password
        };

        // const url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyC_Y0emYKyNy21ffRFkVYxBsSHrE7Tq6dE';
        // const url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyC_Y0emYKyNy21ffRFkVYxBsSHrE7Tq6dEs';
        // const url = 'http://localhost/REST/auth/signInWithPassword?key=AIzaSyC_Y0emYKyNy21ffRFkVYxBsSHrE7Tq6dE';
        // const url = 'http://localhost/REST/auth/signInWithPassword?key=63fd64f817a92212d3e3c2f4140ef3';
        const url = 'auth/signInWithPassword?key=63fd64f817a92212d3e3c2f4140ef3';

        axios.post(url, transformToFormData(authData))
            .then(response => {
                try {
                    // console.log(response);
                    const expirationDate = new Date( new Date().getTime() + response.data.expiresIn * 1000);
                    localStorage.setItem('token', response.data.idToken);
                    localStorage.setItem('expirationDate', expirationDate);
                    localStorage.setItem('userId', response.data.localId);
                    localStorage.setItem('role', response.data.role);
                    localStorage.setItem('vacFacility', response.data.vacFacility);
                    localStorage.setItem('barangay', response.data.barangay);
                    dispatch(authSuccess(response.data.idToken, response.data.localId, response.data.role, response.data.vacFacility, response.data.barangay));
                    dispatch(checkAuthTimeout(response.data.expiresIn));
                } catch (error) {
                    // console.log(error.toJSON());
                    dispatch(authFail({message: "Invalid Credentials!"}));
                }
            })
            .catch(err => {
                dispatch(authFail(err.response.data.error));
            });
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                const userId = localStorage.getItem('userId');
                const role = localStorage.getItem('role');
                const vacFacility = localStorage.getItem('vacFacility');
                const barangay = localStorage.getItem('barangay');
                dispatch(authSuccess(token, userId, role, vacFacility, barangay));
                dispatch(checkAuthTimeout( (expirationDate.getTime() - new Date().getTime()) / 1000 ));
            }
        }
    }
};