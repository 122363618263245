import React from 'react';
import classes from './WorkerType.module.css';

import { Card, CardText, Row, Col } from 'reactstrap';
import { Table } from 'reactstrap';

import { numberFormat } from '../../../shared/utility';

const workerType = (props) => {
    let totalCount = 0;

    Object.entries(props.workerTypeCount).map(([key, value]) => { 
        totalCount += parseInt(value);
    })

    const data = Object.entries(props.workerTypeCount).map(([key, value]) => { 
        return (<tr key={key}>
                    <th>{key}</th> 
                    <td>
                        {numberFormat(value, 0, '.', ',')} 
                        <br></br> 
                        <small>({numberFormat(((value/totalCount) * 100), 2, '.', ',')}%)</small>
                    </td> 
                </tr>);
    });

    return (
        <Row>
            <Col sm="12" className={classes.Container} style={{paddingLeft : '0px'}}>
                <Card body className={classes.Card}>
                    <CardText>
                        <h5>Type of Worker</h5>
                        <Table className={classes.Table} striped>
                            <thead>
                                <tr>
                                    <th>Worker Type</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data}
                            </tbody>
                        </Table>
                    </CardText>
                </Card>
            </Col>
        </Row>
        
    );
}

export default workerType;