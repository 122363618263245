export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const SCANNER_START = 'SCANNER_START';
export const SCANNER_SUCCESS = 'SCANNER_SUCCESS';
export const SCANNER_FAIL = 'SCANNER_FAIL';
export const SET_SCANNER_CURRENT_QRCODE = 'SET_SCANNER_CURRENT_QRCODE';
export const TOGGLE_SCANNER_MODAL = 'TOGGLE_SCANNER_MODAL';

export const FETCH_HOUSEHOLDS_START = 'FETCH_HOUSEHOLDS_START';
export const FETCH_HOUSEHOLDS_SUCCESS = 'FETCH_HOUSEHOLDS_SUCCESS';
export const FETCH_HOUSEHOLDS_FAIL = 'FETCH_HOUSEHOLDS_FAIL';
export const SET_HOUSEHOLD_CURRENT_PAGE = 'SET_HOUSEHOLD_CURRENT_PAGE';
export const SUBMIT_HOUSEHOLD_START = 'SUBMIT_HOUSEHOLD_START';
export const SUBMIT_HOUSEHOLD_SUCCESS = 'SUBMIT_HOUSEHOLD_SUCCESS';
export const SUBMIT_HOUSEHOLD_FAIL = 'SUBMIT_HOUSEHOLD_FAIL';
export const UPDATE_FORM = 'UPDATE_FORM';
export const CLEAR_SUBMIT_ERROR = 'CLEAR_SUBMIT_ERROR';
export const CLEAR_SUBMIT_SUCCESS = 'CLEAR_SUBMIT_SUCCESS';
export const FETCH_CURRENT_HOUSEHOLD_START = 'FETCH_CURRENT_HOUSEHOLD_START';
export const FETCH_CURRENT_HOUSEHOLD_SUCCESS = 'FETCH_CURRENT_HOUSEHOLD_SUCCESS';
export const FETCH_CURRENT_HOUSEHOLD_FAIL = 'FETCH_CURRENT_HOUSEHOLD_FAIL';
export const CLEAR_FETCH_ERROR = 'CLEAR_FETCH_ERROR';

export const FETCH_FAMILIES_START = 'FETCH_FAMILIES_START';
export const FETCH_FAMILIES_SUCCESS = 'FETCH_FAMILIES_SUCCESS';
export const FETCH_FAMILIES_FAIL = 'FETCH_FAMILIES_FAIL';
export const FETCH_CURRENT_FAMILY_START = 'FETCH_CURRENT_FAMILY_START';
export const FETCH_CURRENT_FAMILY_SUCCESS = 'FETCH_CURRENT_FAMILY_SUCCESS';
export const FETCH_CURRENT_FAMILY_FAIL = 'FETCH_CURRENT_FAMILY_FAIL';
export const CLEAR_FAMILY_FETCH_ERROR = 'CLEAR_FAMILY_FETCH_ERROR';
export const DELETE_FAMILY_START = 'DELETE_FAMILY_START';
export const DELETE_FAMILY_SUCCESS = 'DELETE_FAMILY_SUCCESS';
export const DELETE_FAMILY_FAIL = 'DELETE_FAMILY_FAIL';
export const CLEAR_FAMILY_DELETE_ERROR = 'CLEAR_FAMILY_DELETE_ERROR';
export const CLEAR_FAMILY_DELETE_SUCCESS = 'CLEAR_FAMILY_DELETE_SUCCESS';
export const CREATE_FAMILY_START = 'CREATE_FAMILY_START';
export const CREATE_FAMILY_SUCCESS = 'CREATE_FAMILY_SUCCESS';
export const CREATE_FAMILY_FAIL = 'CREATE_FAMILY_FAIL';
export const CLEAR_FAMILY_CREATE_ERROR = 'CLEAR_FAMILY_CREATE_ERROR';
export const CLEAR_FAMILY_CREATE_SUCCESS = 'CLEAR_FAMILY_CREATE_SUCCESS';

export const FETCH_MEMBERS_START = 'FETCH_MEMBERS_START';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_FAIL = 'FETCH_MEMBERS_FAIL';
export const SET_MEMBER_CURRENT_PAGE = 'SET_MEMBER_CURRENT_PAGE';
export const SUBMIT_MEMBER_START = 'SUBMIT_MEMBER_START';
export const SUBMIT_MEMBER_SUCCESS = 'SUBMIT_MEMBER_SUCCESS';
export const SUBMIT_MEMBER_FAIL = 'SUBMIT_MEMBER_FAIL';
export const UPDATE_MEMBER_FORM = 'UPDATE_MEMBER_FORM';
export const CLEAR_MEMBER_SUBMIT_ERROR = 'CLEAR_MEMBER_SUBMIT_ERROR';
export const CLEAR_MEMBER_SUBMIT_SUCCESS = 'CLEAR_MEMBER_SUBMIT_SUCCESS';
export const FETCH_CURRENT_MEMBER_START = 'FETCH_CURRENT_MEMBER_START';
export const FETCH_CURRENT_MEMBER_SUCCESS = 'FETCH_CURRENT_MEMBER_SUCCESS';
export const FETCH_CURRENT_MEMBER_FAIL = 'FETCH_CURRENT_MEMBER_FAIL';
export const CLEAR_MEMBER_FETCH_ERROR = 'CLEAR_MEMBER_FETCH_ERROR';
export const DELETE_MEMBER_START = 'DELETE_MEMBER_START';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_FAIL = 'DELETE_MEMBER_FAIL';
export const CLEAR_MEMBER_DELETE_ERROR = 'CLEAR_MEMBER_DELETE_ERROR';
export const CLEAR_MEMBER_DELETE_SUCCESS = 'CLEAR_MEMBER_DELETE_SUCCESS';
export const SEARCH_MEMBER = 'SEARCH_MEMBER';
export const SEARCH_MEMBER_START = 'SEARCH_MEMBER_START';
export const SEARCH_MEMBER_SUCCESS = 'SEARCH_MEMBER_SUCCESS';
export const SEARCH_MEMBER_FAIL = 'SEARCH_MEMBER_FAIL';
export const CLEAR_MEMBER_SEARCH_ERROR = 'CLEAR_MEMBER_SEARCH_ERROR';

export const FETCH_DASHBOARD_START = 'FETCH_DASHBOARD_START';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_FAIL = 'FETCH_DASHBOARD_FAIL';

export const VAC_SCANNER_START = 'VAC_SCANNER_START';
export const VAC_SCANNER_SUCCESS = 'VAC_SCANNER_SUCCESS';
export const VAC_SCANNER_FAIL = 'VAC_SCANNER_FAIL';
export const VAC_SET_SCANNER_CURRENT_QRCODE = 'VAC_SET_SCANNER_CURRENT_QRCODE';
export const FETCH_VACCINEE_START = 'FETCH_VACCINEE_START';
export const FETCH_VACCINEE_SUCCESS = 'FETCH_VACCINEE_SUCCESS';
export const FETCH_VACCINEE_FAIL = 'FETCH_VACCINEE_FAIL';
export const CLEAR_VACCINEE_FETCH_ERROR = 'CLEAR_VACCINEE_FETCH_ERROR';

export const SUBMIT_TRIAGE_START = 'SUBMIT_TRIAGE_START';
export const SUBMIT_TRIAGE_SUCCESS = 'SUBMIT_TRIAGE_SUCCESS';
export const SUBMIT_TRIAGE_FAIL = 'SUBMIT_TRIAGE_FAIL';
export const CLEAR_SUBMIT_TRIAGE_MESSAGE = 'CLEAR_SUBMIT_TRIAGE_MESSAGE';

export const SUBMIT_SCREENING_START = 'SUBMIT_SCREENING_START';
export const SUBMIT_SCREENING_SUCCESS = 'SUBMIT_SCREENING_SUCCESS';
export const SUBMIT_SCREENING_FAIL = 'SUBMIT_SCREENING_FAIL';
export const CLEAR_SUBMIT_SCREENING_MESSAGE = 'CLEAR_SUBMIT_SCREENING_MESSAGE';

export const SUBMIT_VACCINATION_START = 'SUBMIT_VACCINATION_START';
export const SUBMIT_VACCINATION_SUCCESS = 'SUBMIT_VACCINATION_SUCCESS';
export const SUBMIT_VACCINATION_FAIL = 'SUBMIT_VACCINATION_FAIL';
export const CLEAR_SUBMIT_VACCINATION_MESSAGE = 'CLEAR_SUBMIT_VACCINATION_MESSAGE';

export const SUBMIT_HOLDING_START = 'SUBMIT_HOLDING_START';
export const SUBMIT_HOLDING_SUCCESS = 'SUBMIT_HOLDING_SUCCESS';
export const SUBMIT_HOLDING_FAIL = 'SUBMIT_HOLDING_FAIL';
export const CLEAR_SUBMIT_HOLDING_MESSAGE = 'CLEAR_SUBMIT_HOLDING_MESSAGE';

export const FETCH_FORM_START = 'FETCH_FORM_START';
export const FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS';
export const FETCH_FORM_FAIL = 'FETCH_FORM_FAIL';
export const CLEAR_FORM_FETCH_ERROR = 'CLEAR_FORM_FETCH_ERROR';

export const SUBMIT_LINKING_START = 'SUBMIT_LINKING_START';
export const SUBMIT_LINKING_SUCCESS = 'SUBMIT_LINKING_SUCCESS';
export const SUBMIT_LINKING_FAIL = 'SUBMIT_LINKING_FAIL';
export const CLEAR_SUBMIT_LINKING_MESSAGE = 'CLEAR_SUBMIT_LINKING_MESSAGE';
export const FETCH_VACCINEES_START = 'FETCH_VACCINEES_START';
export const FETCH_VACCINEES_SUCCESS = 'FETCH_VACCINEES_SUCCESS';
export const FETCH_VACCINEES_FAIL = 'FETCH_VACCINEES_FAIL';
export const CLEAR_FETCH_VACCINEES_MESSAGE = 'CLEAR_FETCH_VACCINEES_MESSAGE';

export const SUBMIT_VACCINEE_PROFILE_START = 'SUBMIT_VACCINEE_PROFILE_START';
export const SUBMIT_VACCINEE_PROFILE_SUCCESS = 'SUBMIT_VACCINEE_PROFILE_SUCCESS';
export const SUBMIT_VACCINEE_PROFILE_FAIL = 'SUBMIT_VACCINEE_PROFILE_FAIL';
export const CLEAR_SUBMIT_VACCINEE_PROFILE_MESSAGE = 'CLEAR_SUBMIT_VACCINEE_PROFILE_MESSAGE';