import React from 'react';
import classes from './VaccineRejection.module.css';

import { Card, CardText, Row, Col } from 'reactstrap';
import { Doughnut } from "react-chartjs-2";
import { IconContext } from "react-icons";
import { FaTimes } from "react-icons/fa";

import { numberFormat } from '../../../shared/utility';

const vaccineRejection = (props) => {
    const data = {
        labels: ['Complacency', 'Confidence', 'Convenience'],
        datasets: [
            {
                label: "Vaccine Rejection",
                data: [props.complacency, props.confidence, props.convenience],
                fill: true,
                backgroundColor: ["#f8c291", "#6a89cc", "#ff6b6b"],
                borderColor: ["#f8c291", "#6a89cc", "#ff6b6b"]
            },
            // {
            //     label: "Second dataset",
            //     data: [33, 25, 35, 51, 54, 76],
            //     fill: false,
            //     borderColor: "#742774"
            // }
        ]
    };

    const options = {
        title:{
            display: true,
            text: 'Vaccine Rejection',
            fontSize: 20
        }
    };
        
    return (
        <Row className={classes.VaccineRejection}>
            <Col sm="12">
                <Card body className={classes.Card}>
                    <CardText>
                        <Doughnut data={data} options={options}/>
                    </CardText>
                    <Row>
                        <Col xs="4" className={classes.ComplacencyCount}>
                            <IconContext.Provider value={{ className: [classes.CountIcon, classes.CountIconComplacency].join(' ')}}>
                                <FaTimes/>
                            </IconContext.Provider> 
                            {numberFormat(props.complacency, 0, '', ',')}
                            <br></br>
                            <small> {numberFormat(((parseInt(props.complacency)/(parseInt(props.complacency) + parseInt(props.confidence) + parseInt(props.convenience))) * 100), 2, '.', ',')}%</small>
                        </Col>
                        <Col xs="4" className={classes.ConfidenceCount}>
                            <IconContext.Provider value={{ className: [classes.CountIcon, classes.CountIconConfidence].join(' ')}}>
                                <FaTimes/>
                            </IconContext.Provider> 
                            {numberFormat(props.confidence, 0, '', ',')}
                            <br></br>
                            <small> {numberFormat(((parseInt(props.confidence)/(parseInt(props.complacency) + parseInt(props.confidence) + parseInt(props.convenience))) * 100), 2, '.', ',')}%</small>
                        </Col>
                        <Col xs="4" className={classes.ConvenienceCount}>
                            <IconContext.Provider value={{ className: [classes.CountIcon, classes.CountIconConvenience].join(' ')}}>
                                <FaTimes/>
                            </IconContext.Provider> 
                            {numberFormat(props.convenience, 0, '', ',')}
                            <br></br>
                            <small> {numberFormat(((parseInt(props.convenience)/(parseInt(props.complacency) + parseInt(props.confidence) + parseInt(props.convenience))) * 100), 2, '.', ',')}%</small>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}

export default vaccineRejection;