import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    submitting: false,
    submitError: null,
    submitSuccess: null,
    controls: {
        dosage: {
            label: 'Dosage', 
            elementType: 'select',
            elementConfig: {
                options: [
                    { value: '', displayValue: 'Pumili' },
                    { value: 'Dose 1', displayValue: 'Dose 1' },
                    { value: 'Dose 2', displayValue: 'Dose 2' },
                ]
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            valueType: 'Dosage',
            disabled: false,
            hidden: false
        },
        holding_side_effects: {
            label: 'Side Effects', 
            elementType: 'checkbox',
            elementConfig: {
                options: [
                    { value: 'Pain', displayValue: 'Pain' },
                    { value: 'Redness', displayValue: 'Redness' },
                    { value: 'Swelling', displayValue: 'Swelling' },
                    { value: 'Tiredness', displayValue: 'Tiredness' },
                    { value: 'Headache', displayValue: 'Headache' },
                    { value: 'Muscle Pain', displayValue: 'Muscle Pain' },
                    { value: 'Chills', displayValue: 'Chills' },
                    { value: 'Fever', displayValue: 'Fever' },
                    { value: 'Nausea', displayValue: 'Nausea' },
                ]
            },
            // value: 'test@test.com',
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Choice',
            disabled: false,
            hidden: false,
            touched: false
        },
        holding_remarks: {
            label: 'Remarks (one per Line)', 
            elementType: 'textarea',
            elementConfig: {
                type: 'textarea',
                placeholder: 'Hit enter to start typing'
            },
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: '',
            disabled: false,
            hidden: false,
            touched: false
        },
    }
};

const submitHoldingStart = (state, action) => {
    return updateObject(state, { 
        submitting : true,
        submitSuccess: null,
        submitError: null
    });
};

const submitHoldingSuccess = (state, action) => {
    return updateObject(state, {
        submitting : false, 
        submitSuccess: action.submitSuccess 
    });
};

const submitHoldingFail = (state, action) => {
    return updateObject(state, { 
        submitting : false, 
        submitError: action.submitError,
    });
};

const clearSubmitHoldingMessage = (state, action) => {
    return updateObject(state, {submitError: action.submitError, submitSuccess: action.submitSuccess});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SUBMIT_HOLDING_START: return submitHoldingStart(state, action);
        case actionTypes.SUBMIT_HOLDING_SUCCESS: return submitHoldingSuccess(state, action);
        case actionTypes.SUBMIT_HOLDING_FAIL: return submitHoldingFail(state, action);
        case actionTypes.CLEAR_SUBMIT_HOLDING_MESSAGE: return clearSubmitHoldingMessage(state, action);
        default: return state;
    }
}

export default reducer;