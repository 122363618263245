import * as actionTypes from './actionTypes';
import axios from '../../axios-orders';

// import { transformToFormData } from '../../shared/utility';

export const submitHoldingStart = () => {
    return {
        type: actionTypes.SUBMIT_HOLDING_START
    }
};

export const submitHoldingSuccess = (success) => {
    return {
        type: actionTypes.SUBMIT_HOLDING_SUCCESS,
        submitSuccess: success
    };
};

export const submitHoldingFail = (error) => {
    return {
        type: actionTypes.SUBMIT_HOLDING_FAIL,
        submitError: error
    };
};

export const clearSubmitHoldingMessage = () => {
    return {
        type: actionTypes.CLEAR_SUBMIT_HOLDING_MESSAGE,
        submitError: null,
        submitSuccess: null
    };
}

// Middleware
export const submitHolding = (token, formID, formData) => {
    return dispatch => {
        dispatch(submitHoldingStart());
        axios.post('/Baliwag_HIMS_VAC/submitHolding?auth=' + token + '&actionMode=UPDATE&formID=' + formID, formData)
            .then(response => {
                if (!response.data.error) {
                    dispatch(submitHoldingSuccess(response.data.message));
                } else {
                    dispatch(submitHoldingFail(response.data.error));
                }
            }).catch(err => {
                console.log("ERR", err);
                dispatch(submitHoldingFail("Something went wrong!"));
            });
    };
};