import React from 'react';
import classes from './Network.module.css';
import Aux from '../../../hoc/Aux/Aux';
import { Row, Col } from 'reactstrap';

import { numberFormat } from '../../../shared/utility';

const network = (props) => {
    
    const network = Object.entries(props.counts).map(([key, value]) => { 
            return (
                <Aux key={key}>
                    <Col className={classes.Box} sm="3" xs="12">
                        <div className={classes.NetworkCount}>{numberFormat(value, 0, '', ',')}</div>
                        <div className={classes.NetworkName}>{key}</div> 
                    </Col>
                </Aux>
            );
        }
    )
        
    return (
        <Row className={classes.Network}>
            {network} 
        </Row>
    );
}

export default network;