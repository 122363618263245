import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    currentQRCode: '',
    showModal: false,
    error: null,
    actionMode: null
};

const scannerStart = (state, action) => {
    return updateObject(state, {
        currentQRCode: '',
        showModal: false,
        error: null,
        actionMode: null
    });
}

const scannerSuccess = (state, action) => {
    return updateObject(state, {
        currentQRCode: action.currentQRCode,
        showModal: action.showModal,
        error: null,
        actionMode: action.actionMode
    });
}

const scannerFail = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
}

const setScannerCurrentQRCode = (state, action) => {
    return updateObject(state, {currentQRCode: action.currentQRCode, actionMode: action.actionMode});
}

const toggleScannerModal = (state, action) => {
    return updateObject(state, {showModal: action.showModal});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SCANNER_START: return scannerStart(state, action);
        case actionTypes.SCANNER_SUCCESS: return scannerSuccess(state, action);
        case actionTypes.SCANNER_FAIL: return scannerFail(state, action);
        case actionTypes.SET_SCANNER_CURRENT_QRCODE: return setScannerCurrentQRCode(state, action);
        case actionTypes.TOGGLE_SCANNER_MODAL: return toggleScannerModal(state, action);
        default: return state;
    }
}

export default reducer;