import React from 'react';
import classes from './Pregnancy.module.css';

import { Card, CardText, Row, Col } from 'reactstrap';
import { Doughnut } from "react-chartjs-2";
import { IconContext } from "react-icons";
import { BiFemale } from "react-icons/bi";
import { MdPregnantWoman } from "react-icons/md";

import { numberFormat } from '../../../shared/utility';

const pregnancy = (props) => {
    const data = {
        labels: ["Pregnant", "Not Pregnant"],
        datasets: [
            {
                label: "Pregnancy",
                data: [props.pregnant, props.notPregnant],
                fill: true,
                backgroundColor: ["#f8c291", "#6a89cc"],
                borderColor: ["#f8c291", "#6a89cc"]
            },
            // {
            //     label: "Second dataset",
            //     data: [33, 25, 35, 51, 54, 76],
            //     fill: false,
            //     borderColor: "#742774"
            // }
        ]
    };

    const options = {
        title:{
            display: true,
            text: 'Pregnancy',
            fontSize: 20
        }
    };
        
    return (
        <Row className={classes.Pregnancy}>
            <Col sm="12">
                <Card body className={classes.Card}>
                    <CardText>
                        <Doughnut data={data} options={options}/>
                    </CardText>
                    <Row>
                        <Col xs="6" className={classes.PregnantCount}>
                            <IconContext.Provider value={{ className: [classes.CountIcon, classes.CountIconPregnant].join(' ')}}>
                                <MdPregnantWoman/>
                            </IconContext.Provider> 
                            {numberFormat(props.pregnant, 0, '', ',')}
                            <br></br>
                            <small> {numberFormat(((parseInt(props.pregnant)/(parseInt(props.pregnant) + parseInt(props.notPregnant))) * 100), 2, '.', ',')}%</small>
                        </Col>
                        <Col xs="6" className={classes.NotPregnantCount}>
                            <IconContext.Provider value={{ className: [classes.CountIcon, classes.CountIconNotPregnant].join(' ')}}>
                                <BiFemale/>
                            </IconContext.Provider> 
                            {numberFormat(props.notPregnant, 0, '', ',')}
                            <br></br>
                            <small> {numberFormat(((parseInt(props.notPregnant)/(parseInt(props.pregnant) + parseInt(props.notPregnant))) * 100), 2, '.', ',')}%</small>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}

export default pregnancy;