import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import QrReader from 'react-qr-reader2';
import { Button, Form, FormGroup, Label, Input, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import * as actions from '../../store/actions/index';

// Higher Order Components
import Aux from '../../hoc/Aux/Aux';
// import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
// import axios from '../../axios-orders';

// UI
// import Spinner from '../../components/UI/Spinner/Spinner';

// Icons
// import { IconContext } from "react-icons";
// import { BsFillGrid3X3GapFill } from "react-icons/bs";

// Styles
import classes from './Scanner.module.css';

// Images
import householdStickerSample from '../../assets/images/household-sticker-sample.png';

export class Scanner extends Component {

    state = {
        label: "Hold QR Code Steady and Clear to Scan",
        alert: null
    };

    componentDidMount() {
        this.props.onScannerInit();
    }

    inputChangedHandler = (event) => {
        this.props.onSetScannerCurrentQRCode(event.target.value, "CREATE");
    }

    buttonClickedHandler = (event) => {
        event.preventDefault();
        if (this.props.resultText !== "") {
            this.props.onToggleScannerModal(!this.props.showModal);
        } else {
            this.setState({alert: "QR Code value is empty!"});
        }
    }

    toggleHandler = () => {
        this.props.onToggleScannerModal(!this.props.showModal);
    }

    dismissAlertHandler = () => {
        this.setState({alert: null});
    }

    agreedHandler = () => {
        this.props.onToggleScannerModal(!this.props.showModal);
        this.props.history.push('/households');
    }

    render() {
        const consentModal = (
            <Modal isOpen={this.props.showModal} toggle={this.toggleHandler} fade>
                <ModalHeader toggle={this.toggleHandler}>Dear Respondent</ModalHeader>
                <ModalBody>
                    <strong>
                        Data Privacy Statement for the Baliwag Pagbangon Census 2021 Application
                    </strong>
                    <br></br>
                    <br></br>
                    The Municipal Government of Baliwag (“we” or “us”) puts premium value to the privacy and security of personal data gathered from Baliwagenyos for valid purposes. We provide a privacy policy explaining how we gather the data and how it will be used for planning and decision-making purposes only and not for public use.
                    <br></br>
                    <ol type="I">
                        <li>
                            Collection of Information
                            <br></br>
                            The following are the information to be gathered thru an online application/system in a questionnaire format:
                            <ol type="a">
                                <li>Full Name</li>
                                <li>Contact Details (Home Address, Cellphone Number)</li>
                                <li>House Location</li>
                                <li>Housing and Household Characteristics</li>
                                <li>Demography (Gender, Birthdate, Civil Status)</li>
                                <li>Educational Attainment</li>
                                <li>Employment Status and Source of Income</li>
                                <li>Covid-19 Vaccine Preference</li>
                            </ol>
                        </li>
                        <li>
                            Use of Information
                            <br></br>
                            The information gathered shall be used:
                            <ol type="a">
                                <li>As basis for the inoculation of Covid-19 vaccine to individuals;</li>
                                <li>To establish and maintain an updated database for the different programs of the Municipal Government of Baliwag; and</li>
                                <li>To perform an analysis on the data to assess the effectivity of its services.</li>
                            </ol>
                        </li>
                        <li>
                            Processing of Information
                            <br></br>
                            We collect, record, store, update or modify, retrieve, use or consolidate your personal information pursuant to the purposes stated above on a one-time basis thru a questionnaire format application.
                        </li>
                        <li>
                            Information Sharing and Disclosure
                            <br></br>
                            We may disclose the information in accordance with the purposes mentioned above as follows:
                            <ol type="a">
                                <li>To the Municipal Health Office and COVID-19 Vaccine Task Force that shall perform and implement the COVID-19 Vaccination Plan;</li>
                                <li>To the different departments and offices of the Municipal Government of Baliwag for planning and decision-making purposes;</li>
                                <li>With third parties in order to investigate, prevent, or take action regarding potentially illegal activities, suspected fraud, situations involving potential threats to any person, us, or the local government, or violations of our policies, the law or our terms of use, to verify or enforce compliance with the policies governing our services; and</li>
                                <li>When the processing of personal information is outsourced by us to a third party, the processing will be subject to written agreements between us and the third parties processing the data, in accordance with the requirements of the Data Privacy Act of 2012 and Bayanihan to Heal As One Act of 2020. These written agreements specify the rights and obligations of each party and will provide that the third party has adequate security measures in place and will only process the personal information on our specific written instructions.</li>
                            </ol>
                        </li>
                        <li>
                            Other important information about your privacy
                            <br></br>
                            <ol type="a">
                                <li>
                                    Data Security
                                    <br></br>
                                    We have put in place physical, electronic, and managerial procedures designed to help prevent unauthorized access, to maintain data security, and to use correctly the Information we collect online. These safeguards vary based on the sensitivity of the information that we collect and store.
                                </li>
                                <li>
                                    Complaints, Concerns, and Questions
                                    <br></br>
                                    If you have any complaints, concerns, or questions regarding the processing of your personal information, please contact our Data Protection Officer at micto@baliwag.gov.ph.
                                </li>
                            </ol>
                        </li>
                        <li>
                            Rights as the data subject
                            <br></br>
                            You, as the Data Subject, have rights under the Data Privacy Act of 2012, which include the following:
                            <ol type="a">
                                <li>The right to access his/her personal information.</li>
                                <li>The right to make corrections to his/her personal information.</li>
                                <li>The right to object to the processing of his/her personal information.</li>
                                <li>The right to erasure or blocking of his/her personal information.</li>
                                <li>The right to be informed of the existence of processing of his/her personal information.</li>
                                <li>The right to damages.</li>
                                <li>The right to lodge a complaint before the National Privacy Commission.</li>
                            </ol>
                        </li>

                        <br></br>
                        <h4>USER'S CONSENT</h4>
                        <br></br>
                        I have read the Institution’s Data Privacy Statement and express my consent for the Municipality of Baliwag through the Baliwag Pagbangon Census 2021 Application to collect, record, organize, update or modify, retrieve, block, erase or destruct my personal data as part of my information.
                        <br></br>
                        I hereby affirm my right to be informed, object to processing, access and rectify, suspend or withdraw my personal data, and be indemnified in case of damages pursuant to the provisions of the Republic Act No. 10173 of the Philippines otherwise known as the Data Privacy Act of 2012 and its corresponding Implementing Rules and Regulations.
                        <br></br>
                        <br></br>
                        <i>
                            For any questions and concern, you may reach us at (044) 798-0391 Loc. 4286 (ICTO) or 7222 (PACC).
                        </i>
                    </ol>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.agreedHandler}>The Respondent Agreed</Button>
                    <Button color="secondary" onClick={this.toggleHandler}>Close</Button>
                </ModalFooter>
            </Modal>
        );

        const alert = (this.state.alert) ? <Alert color="info" toggle={this.dismissAlertHandler}>{this.state.alert}</Alert> : null;

        return (
            <Aux>
                {consentModal}
                <Row>
                    <Col xs="12">
                        <div className={classes.HouseholdSticker}>
                            <strong>Sample Sticker</strong>
                            <img src={householdStickerSample} alt="Household Sticker Sample"/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm={{ size: 6, offset: 3 }}>
                        <QrReader
                            delay={500} 
                            style={{width: "100%", height: "auto", marginTop: "5%"}} 
                            onError={(error) => this.props.onScannerFail(error)} 
                            onScan={(result) => (result) ? this.props.onScannerSuccess(result, "CREATE") : null} 
                            facingMode="environment"
                        />
                    </Col>
                    <Col xs="12" sm={{ size: 6, offset: 3 }}>
                        <Form style={{textAlign: "center"}}>
                            <FormGroup>
                                <Label for="resultTextLabel">{this.state.label}</Label>
                                {alert} 
                                <Input 
                                    type="text" 
                                    name="resultText" 
                                    value={this.props.resultText} 
                                    id="resultTextLabel" 
                                    placeholder="Type here if QR is not present" 
                                    onChange={(event) => this.inputChangedHandler(event)} 
                                    style={{textAlign: "center"}}/>
                            </FormGroup>
                            <Button onClick={this.buttonClickedHandler}>Proceed</Button>
                        </Form>
                    </Col>
                </Row>
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return { 
        resultText: state.scanner.currentQRCode,
        showModal: state.scanner.showModal,
        actionMode: state.scanner.actionMode
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onScannerInit: () => dispatch(actions.scannerStart()), 
        onScannerSuccess: (currentQRCode, actionMode) => dispatch(actions.scannerSuccess(currentQRCode, true, actionMode)),
        onScannerFail: (error) => dispatch(actions.scannerFail(error)),
        onSetScannerCurrentQRCode: (currentQRCode, actionMode) => dispatch(actions.setScannerCurrentQRCode(currentQRCode, actionMode)),
        onToggleScannerModal: (showModal) => dispatch(actions.toggleScannerModal(showModal)),
    };
};

// export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler( Scanner, axios ));
export default connect(mapStateToProps, mapDispatchToProps)( Scanner );