import React from 'react';
import classes from './Employment.module.css';

import { Card, CardText, Row, Col } from 'reactstrap';
import { Pie } from "react-chartjs-2";
import { IconContext } from "react-icons";
import { FaRegTimesCircle } from "react-icons/fa";
import { MdBusinessCenter } from "react-icons/md";

import { numberFormat } from '../../../shared/utility';

const employment = (props) => {
    const data = {
        labels: ["Employed", "Not Employed"],
        datasets: [
            {
                label: "Employment",
                data: [props.employed, props.notEmployed],
                fill: true,
                backgroundColor: ["#f8c291", "#6a89cc"],
                borderColor: ["#f8c291", "#6a89cc"]
            }
        ]
    };

    const options = {
        title:{
            display: true,
            text: 'Employment',
            fontSize: 20
        }
    };
    
    return (
        <Row className={classes.Employment}>
            <Col sm="12">
                <Card body className={classes.Card}>
                    <CardText>
                        <Pie data={data} options={options}/>
                    </CardText>
                    <Row>
                        <Col xs="6" className={classes.EmpCount}>
                            <IconContext.Provider value={{ className: [classes.CountIcon, classes.CountIconEmp].join(' ')}}>
                                <MdBusinessCenter/>
                            </IconContext.Provider> 
                            {numberFormat(props.employed, 0, '', ',')}
                            <br></br>
                            <small> {numberFormat(((parseInt(props.employed)/(parseInt(props.employed) + parseInt(props.notEmployed))) * 100), 2, '.', ',')}%</small>
                        </Col>
                        <Col xs="6" className={classes.NotEmpCount}>
                            <IconContext.Provider value={{ className: [classes.CountIcon, classes.CountIconNotEmp].join(' ')}}>
                                <FaRegTimesCircle/>
                            </IconContext.Provider> 
                            {numberFormat(props.notEmployed, 0, '', ',')}
                            <br></br>
                            <small> {numberFormat(((parseInt(props.notEmployed)/(parseInt(props.employed) + parseInt(props.notEmployed))) * 100), 2, '.', ',')}%</small>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}

export default employment;