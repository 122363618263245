import React from 'react';
import classes from './Sex.module.css';

import { Card, CardText, Row, Col } from 'reactstrap';
import { Doughnut } from "react-chartjs-2";
import { IconContext } from "react-icons";
import { BiMale, BiFemale } from "react-icons/bi";

import { numberFormat } from '../../../shared/utility';

const sex = (props) => {
    const data = {
        labels: ["Male", "Female"],
        datasets: [
            {
                label: "Sex",
                data: [props.male, props.female],
                fill: true,
                backgroundColor: ["#f8c291", "#6a89cc"],
                borderColor: ["#f8c291", "#6a89cc"]
            },
            // {
            //     label: "Second dataset",
            //     data: [33, 25, 35, 51, 54, 76],
            //     fill: false,
            //     borderColor: "#742774"
            // }
        ]
    };

    const options = {
        title:{
            display: true,
            text: 'Sex',
            fontSize: 20
        }
    };
        
    return (
        <Row className={classes.Sex}>
            <Col sm="12">
                <Card body className={classes.Card}>
                    <CardText>
                        <Doughnut data={data} options={options}/>
                    </CardText>
                    <Row>
                        <Col xs="6" className={classes.MaleCount}>
                            <IconContext.Provider value={{ className: [classes.CountIcon, classes.CountIconMale].join(' ')}}>
                                <BiMale/>
                            </IconContext.Provider> 
                            {numberFormat(props.male, 0, '', ',')}
                            <br></br>
                            <small> {numberFormat(((parseInt(props.male)/(parseInt(props.male) + parseInt(props.female))) * 100), 2, '.', ',')}%</small>
                        </Col>
                        <Col xs="6" className={classes.FemaleCount}>
                            <IconContext.Provider value={{ className: [classes.CountIcon, classes.CountIconFemale].join(' ')}}>
                                <BiFemale/>
                            </IconContext.Provider> 
                            {numberFormat(props.female, 0, '', ',')}
                            <br></br>
                            <small> {numberFormat(((parseInt(props.female)/(parseInt(props.male) + parseInt(props.female))) * 100), 2, '.', ',')}%</small>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}

export default sex;