import * as actionTypes from './actionTypes';
import axios from '../../axios-orders';

// import { transformToFormData } from '../../shared/utility';

export const submitVaccineeProfileStart = () => {
    return {
        type: actionTypes.SUBMIT_VACCINEE_PROFILE_START
    }
};

export const submitVaccineeProfileSuccess = (success) => {
    return {
        type: actionTypes.SUBMIT_VACCINEE_PROFILE_SUCCESS,
        submitSuccess: success
    };
};

export const submitVaccineeProfileFail = (error) => {
    return {
        type: actionTypes.SUBMIT_VACCINEE_PROFILE_FAIL,
        submitError: error
    };
};

export const clearSubmitVaccineeProfileMessage = () => {
    return {
        type: actionTypes.CLEAR_SUBMIT_VACCINEE_PROFILE_MESSAGE,
        submitError: null,
        submitSuccess: null
    };
}

// Middleware
export const submitVaccineeProfile = (token, iamsafeQRCode, tableContainedWith, formData) => {
    return dispatch => {
        dispatch(submitVaccineeProfileStart());
        axios.post('/Baliwag_HIMS_VAC/vaccineeProfileUpdate?auth=' + token + '&iamsafeQRCode=' + iamsafeQRCode  + '&tableContainedWith=' + tableContainedWith, formData)
            .then(response => {
                if (!response.data.error) {
                    dispatch(submitVaccineeProfileSuccess(response.data.message));
                } else {
                    dispatch(submitVaccineeProfileFail(response.data.error));
                }
            }).catch(err => {
                console.log("ERR", err);
                dispatch(submitVaccineeProfileFail("Something went wrong!"));
            });
    };
};