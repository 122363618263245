import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    submitting: false,
    submitError: null,
    submitSuccess: null,
    controls: {
        triage_temperature: {
            label: 'Temperature', 
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Temperature'
            },
            // value: 'test@test.com',
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            valueType: 'Temperature',
            disabled: false,
            hidden: false,
            touched: false
        },
        triage_possible_symptoms: {
            label: 'Declared Symptoms', 
            elementType: 'checkbox',
            elementConfig: {
                options: [
                    { value: 'Fever', displayValue: 'Fever' },
                    { value: 'Cough', displayValue: 'Cough' },
                    { value: 'Shortness of breath or difficulty of breathing', displayValue: 'Shortness of breath or difficulty of breathing' },
                    { value: 'Chills', displayValue: 'Chills' },
                    { value: 'Repeated shaking with chills', displayValue: 'Repeated shaking with chills' },
                    { value: 'Muscle Pain', displayValue: 'Muscle Pain' },
                    { value: 'Headache', displayValue: 'Headache' },
                    { value: 'Sore throat', displayValue: 'Sore throat' },
                    { value: 'New loss of taste or smell', displayValue: 'New loss of taste or smell' },
                ]
            },
            // value: 'test@test.com',
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: 'Choice',
            disabled: false,
            hidden: false,
            touched: false
        },
        triage_heart_rate: {
            label: 'Heart Rate', 
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Heart Rate'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            valueType: 'Number',
            disabled: false,
            hidden: false,
            touched: false
        },
        triage_pulse_rate: {
            label: 'Pulse Rate', 
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Pulse Rate'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            valueType: 'Number',
            disabled: false,
            hidden: false,
            touched: false
        },
        triage_respiratory_rate: {
            label: 'Respiratory Rate', 
            elementType: 'input',
            elementConfig: {
                type: 'number',
                placeholder: 'Respiratory Rate'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            valueType: 'Number',
            disabled: false,
            hidden: false,
            touched: false
        },
        triage_blood_pressure: {
            label: 'Blood Pressure', 
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Blood Pressure'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            valueType: 'BP',
            disabled: false,
            hidden: false,
            touched: false
        },
        triage_remarks: {
            label: 'Remarks', 
            elementType: 'textarea',
            elementConfig: {
                type: 'textarea',
                placeholder: 'Enter some remarks'
            },
            // value: 'test@test.com',
            value: '',
            validation: {
                // required: true,
            },
            valid: true,
            valueType: '',
            disabled: false,
            hidden: false,
            touched: false
        },
    }
};

const submitTriageStart = (state, action) => {
    return updateObject(state, { 
        submitting : true,
        submitSuccess: null,
        submitError: null
    });
};

const submitTriageSuccess = (state, action) => {
    return updateObject(state, {
        submitting : false, 
        submitSuccess: action.submitSuccess 
    });
};

const submitTriageFail = (state, action) => {
    return updateObject(state, { 
        submitting : false, 
        submitError: action.submitError,
    });
};

const clearSubmitTriageMessage = (state, action) => {
    return updateObject(state, {submitError: action.submitError, submitSuccess: action.submitSuccess});
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SUBMIT_TRIAGE_START: return submitTriageStart(state, action);
        case actionTypes.SUBMIT_TRIAGE_SUCCESS: return submitTriageSuccess(state, action);
        case actionTypes.SUBMIT_TRIAGE_FAIL: return submitTriageFail(state, action);
        case actionTypes.CLEAR_SUBMIT_TRIAGE_MESSAGE: return clearSubmitTriageMessage(state, action);
        default: return state;
    }
}

export default reducer;